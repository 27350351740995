
import cloneDeep from 'lodash-es/cloneDeep';

import {defineComponent} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

import DropdownFilter from '@app/components/dropdown/DropdownFilter.vue';
import Filters from '@app/components/Filters.vue';
import SearchRow from '@app/components/SearchRow.vue';
import Loader from '@app/components/ui-kit/molecules/Loader.vue';
import ModalDelete from '@app/components/ui-kit/molecules/ModalDelete.vue';
import {isGrantedPolicy} from '@app/modules/auth/auth.store';
import EditFilterItemsModal from '@app/modules/filter/blocks/EditFilterItemsModal.vue';
import EditPersonModal from '@app/modules/person/blocks/EditPersonModal.vue';
import VideoList from '@app/modules/video/blocks/VideoList.vue';
import VideoUploadModal from '@app/modules/video/blocks/VideoUploadModal.vue';
import {
    addVideoAccessForUser,
    deleteVideoAccessForUser,
    filterToSpecificationsString,
    getSearchResults,
    getSortedResults,
    getVideos,
    getVideosByIds,
    updateVideo,
} from '@app/modules/video/video.api';
import {api} from '@app/system/api/api-services';
import {IFilterSetDto, IVideoDto, SpecificationParameters} from '@app/system/api/service-proxies/service-proxies';
import {IFilterRow} from '@app/types';
import {fields, optionAnd} from '@app/utils/dictionary';
import {deleteModalBackdrop, getWidthScreen} from '@app/utils/helpers';
import HeaderPage from '@app/views/blocks/Header.vue';

import EditDescriptionModal from '../blocks/EditDescriptionModal.vue';

export let resetVideoList: Function | null = null;

export default defineComponent({
    name: 'VideoConferences',
    components: {
        VideoUploadModal,
        ModalDelete,
        SearchRow,
        Loader,
        VideoList,
        Filters,
        DropdownFilter,
        EditPersonModal,
        EditDescriptionModal,
        EditFilterItemsModal,
        HeaderPage,
    },
    beforeRouteLeave() {
        if (this.filters.length) {
            this.setVideos([])
            this.clearFilters();
        }
    },
    props: {
        defaultFilters: {
            type: Array,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: [],
        },
        numFilters: {
            type: Number,
            default: 0,
        },
    },
    data: function() {
        return {
            selected_person: null,
            selected_video: {
                id: null,
                description: null,
            },
            selected_videos: null,
            allUser: [],

            /* Users Access for videos in `this.videos` by all users in `this.allUser` */
            usersAccess: {},

            // Pagination
            perPage: 20,
            currentPage: 1,
            videosCount: 0,

            isMounted: false,
            isLoading: true,

            // Error
            serverErrorMessage: null,
            filterItems: [] as IFilterSetDto[],
            activeFilterItem: null,

            defaultNumFilters: this.numFilters,
            fromSearchVideoIds: null,
            filterDialogVisible: false,
            fullTextSearchMode: false,
            fullTextSearchPersonResults: [],
            fullTextSearchStr: '',
            screenMobile: 0,
            videosCountSearch: 0,
            isShowMessage: false,
            isDeletedVideo: false,

            countVideosDeleted: 0,
            formData: null,

            isToggleListView: false,
            widthTable: null,
            videosListScrollLeft: 0,

            showPrevButton: false,
            showNextButton: true,

            hasFilterScroll: false,
            isAtBottom: false,
            isEditDescriptionVideo: false,

            isSorting: false,
            isEmptyVideoData: false,
        };
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),
        ...mapGetters('paginationVideos', {
            currentPageState: 'currentPageState',
        }),
        ...mapGetters('filter', {
            filters: 'filters',
        }),
        ...mapGetters('video', {
            videos: 'videos',
            fullTextSearchVideosResult: 'fullTextSearchVideosResult',
        }),

        ...mapState('route', {
            videosInstancePageFullPath: 'fullPath',
        }),
        paginatedSearchVideos() {
            return this.fullTextSearchVideosResult;
        },
        isMobile() {
            return getWidthScreen();
        },
    },

    watch: {
        filters() {
            if ( this.filters.length && !this.filters[0].personFullName) {
                const currentParticipant = localStorage.getItem('currentParticipant') || ''
                this.setCurrentFilterValue(currentParticipant)
            }
        }
    },

    updated() {
        if (this.$refs.filterRow) {
            this.hasFilterScroll = this.$refs.filterRow.scrollWidth > this.$refs.filterRow.clientWidth;
        }
    },

    created() {
        window.addEventListener('resize', this.updateWidth);
    },

    async mounted() {
        this.isToggleListView = localStorage.getItem('isToggleListView') === 'true';
        this.updateWidth();
        deleteModalBackdrop();
        // this.currentPage = this.currentPageState;
        this.closeMobileMenu();

        // resetVideoList = this.resetVideos.bind(this);

        this.serverErrorMessage = null;
        this.fromSearchVideoIds = null;

        if (!this.videos.length) {
            await this.getVideos();
        } else {
            this.isLoading = false;
        }

        if (this.isGrantedPolicy('Recorder.FilterSet')) {
            const filterOwnSpecification = new SpecificationParameters({
                specId: 'DB8C12D0-8745-475D-A3EC-D2083FA80087',
                parameters: {value: true},
            });
            const filterSpecifications = [
                filterOwnSpecification,
            ];
            const filtersResponse = await api.filterSetServiceProxy.pagePost(
                1,
                1000,
                false,
                '1.0',
                filterSpecifications,
            )
                .toPromise();

            this.filterItems = filtersResponse.data
                .filter(filter => {
                    return !filter.isHidden && filter.items?.length && !filter.title.includes('Фильтр для роли');
                })
                .reverse();
        }

        this.isMounted = true;
    },

    methods: {
        ...mapActions('users', {
            getAllUsers: 'getAllList',
        }),

        ...mapActions('filter', {
            getRecognizedPersons: 'getRecognizedPersons',
        }),

        ...mapMutations('paginationVideos', {
            setCurrentPage: 'setCurrentPage',
        }),

        ...mapMutations('filter', {
            setFilter: 'setFilter',
            setCurrentFilterValue: 'setCurrentFilterValue'
        }),

        ...mapMutations('video', {
            setVideos: 'setVideos',
            setViewScreenVideos: 'setViewScreenVideos',
            setFullTextSearchVideos: 'setFullTextSearchVideos',
        }),

        ...mapMutations('route', {
            setFullPath: 'setFullPath',
        }),

        ...mapActions('video', {
            deleteVideo: 'deleteVideo',
        }),

        handleShowEditFilterItemsModal() {
            this.$refs.editFilterItemsModal.show();
        },

        updateWidth() {
            this.screenMobile = window.innerWidth;
        },

        mobileMenuOpen() {
            document.body.className += 'mobile-menu--open';
        },

        closeMobileMenu() {
            document.body.className = document.body.className.replace('mobile-menu--open', '');
        },

        handleChaneFullTextSearch() {
            this.setCurrentPage(1);
            this.fullTextSearch();
        },

        fullTextSearch(isByScroll = false) {
            this.selected_videos = null;
            this.isEmptyVideoData = false;

            if (this.fullTextSearchStr.trim()) {
                if (!this.fullTextSearchMode) {
                    this.fullTextSearchMode = true;
                }
                this.fullTextSearchRequest(isByScroll);
            } else {
                this.fullTextSearchMode = false;
                this.getVideos(false, isByScroll);
            }
            this.isShowMessage = false;
        },

        async fullTextSearchRequest(isByScroll = false) {
            this.isLoading = true;

            if (!this.fullTextSearchVideosResult.length) {
                this.setCurrentPage(1);
                this.isAtBottom = false;
            }

            try {
                const pageNumber = this.currentPageState;
                const pageSize = this.perPage;

                await this.updateQueryFromFilters(false);

                const respData = await getSearchResults(
                    pageNumber,
                    pageSize,
                    this.fullTextSearchStr.trim(),
                    this.filters,
                );

                if (!respData.data.length) {
                    this.isEmptyVideoData = true
                }

                this.fullTextSearchPersonResults = [];

                if (!isByScroll) {
                    this.setFullTextSearchVideos(respData.data)
                } else {
                    respData.data?.forEach(video => {
                        if (!this.fullTextSearchVideosResult.some(existingVideo => existingVideo.id === video.id)) {
                            this.setFullTextSearchVideos([...this.fullTextSearchVideosResult, video]);
                        }
                    });
                }

                this.videosCountSearch = respData.totalCount;
                await this.reInitUsersAccess();

                this.isLoading = false;

            } catch (e) {
                console.warn(e);
            } finally {
                this.isLoading = false;
            }
        },

        async getVideos(isWithoutFilters = false, isByScroll = false) {
            if (this.isDeletedVideo || this.isEditDescriptionVideo) {
                this.setCurrentPage(1);
            }
            try {
                this.serverErrorMessage = null;
                const pageSize = this.perPage;
                const pageNumber = this.currentPageState;

                await this.updateQueryFromFilters(isWithoutFilters);

                const resp = await getVideos(pageNumber, pageSize, this.filters);

                if (!resp.data.length) {
                    this.isEmptyVideoData = true
                }

                if (this.isDeletedVideo || this.isEditDescriptionVideo) {
                    this.setVideos(resp.data);
                } else {
                    if (!isByScroll) {
                        this.setVideos(resp.data);
                    } else {
                        const filteredData = resp.data.filter(newVideo => !this.videos.some(existingVideo => existingVideo.id ===
                            newVideo.id));
                        this.setVideos([...this.videos, ...filteredData]);
                    }
                }

                if (!this.isDeletedVideo) {
                    this.videosCount = resp.totalCount;
                } else {
                    this.videosCount = this.videosCount - this.countVideosDeleted;
                }
            } catch (e) {
                console.log(e);
                this.serverErrorMessage = this.$t('unknown_error');
                this.videos = [];
            } finally {
                this.isLoading = false;
                this.isDeletedVideo = false;
                this.isEditDescriptionVideo = false;
            }
            this.isDeletedVideo = false;
        },

        async editDescription(video) {
            if (!isGrantedPolicy('Recorder.Video', false, video.permissions)) {
                return;
            }
            await this.selectVideo(video);
            this.$refs.editDescriptionModal.show();
        },

        async selectVideo(video) {
            this.selected_video = video;
        },

        toggleFiltersVisibility($eventOrBool) {
            this.setCurrentPage(1);
            if (typeof ($eventOrBool) === 'boolean') {
                this.filterDialogVisible = $eventOrBool;
            } else if ($eventOrBool) {
                if (!$eventOrBool.target.closest('.modal-dialog')) {
                    this.filterDialogVisible = !this.filterDialogVisible;
                }
                if ($eventOrBool.target.closest('.close')) {
                    this.filterDialogVisible = false;
                }
            } else {
                this.filterDialogVisible = !this.filterDialogVisible;
            }
            this.$refs.filterModal.style.display = this.filterDialogVisible ? 'block' : 'none';
        },

        async resetVideos() {
            this.isLoading = true;
            this.clearFilters();
            this.setVideos([]);
            this.setCurrentPage(1);
            this.$router.push({name: 'videos'});
            try {
                await this.getVideos(true);
            } catch (err) {
                console.log(err);
            } finally {
                this.isLoading = false;
            }
        },

        clearFilters() {
            this.fullTextSearchMode = false;
            this.fullTextSearchStr = '';

            this.setFilter([]);
            this.activeFilterItem = null;
            this.perPage = 20;
        },

        handleShowVideoUploadModal() {
            this.$refs.videoUploadModal.show();
        },

        setFiltersRows(rows: IFilterRow[], activeFilterItem: IFilterSetDto | null = null) {
            if (!rows?.length) {
                this.clearFilters();
                return;
            }
            this.setFilter(rows);
            this.activeFilterItem = activeFilterItem;
        },

        saveFilterDto(filterDto: IFilterSetDto) {
            if (filterDto) {
                this.filterItems.push(filterDto);
            }
        },

        changeFilterItem(filterItem: IFilterSetDto) {
            if (this.activeFilterItem === filterItem) {
                this.activeFilterItem = null;
                this.setFiltersRows([]);
            } else {
                this.activeFilterItem = filterItem;

                const filtered = filterItem.items.map(filter => ({
                    firstColumnVal: optionAnd.type,
                    secondColumnVal: filter.propertyName,
                    thirdColumnVal: filter.operator,
                    fourthColumnVal: filter.value,
                }));

                this.setFilter(filtered);
                this.setFiltersRows(this.filters, filterItem);
            }

            this.applyFilters();
        },

        setDefaultNumFilters(defaultNumFilters) {
            this.defaultNumFilters = defaultNumFilters;
        },

        editPersonMobileCallback(person) {
            this.selected_person = String(person.id);
        },

        editPersonCallback(person) {
            this.selected_person = String(person.id);
            setTimeout(() => {
                this.$refs.editPersonModal.show();
            }, 100);
        },

        async changeUserAccessOfVideo(accessGranted, videoId, userId) {
            const cloned = cloneDeep(this.usersAccess);
            this.usersAccess = {};
            this.usersAccess = cloned;
            if (accessGranted) {
                await addVideoAccessForUser(videoId, userId);
            } else {
                await deleteVideoAccessForUser(videoId, userId);
            }

            if (this.fullTextSearchMode) {
                void this.fullTextSearchRequest();
            } else {
                await this.getVideos();
            }
        },

        async getAllUserForAccessUsersListPopover() {
            try {
                this.allUser = [];
            } catch (e) {
                console.warn(e);
                this.allUser = [];
            }
        },

        async reInitUsersAccess() {
            if (this.allUser?.length < 1) {
                await this.getAllUserForAccessUsersListPopover();
            }

            this.usersAccess = {};

            const scope = this;

            const videos = this.fullTextSearchMode ? this.fullTextSearchVideosResult : this.videos;

            const allUsers = this.allUser?.length ? this.allUser : [];

            videos?.forEach((vd) => {
                this.usersAccess[vd.id] = {};
                allUsers.forEach((ur) => {
                    if (ur.is_admin) {
                        return;
                    }

                    const accessGranted = vd.usersAccessGranted.find(u => u.id === ur.id);
                    scope.usersAccess[vd.id][ur.id] = typeof accessGranted !== 'undefined';
                });
            });

        },

        async updateQueryFromFilters(isWithoutFilters = false) {
            const pageNumber = this.currentPageState;

            if (this.$route.query?.personId && !isWithoutFilters) {

                const filtered = this.filters.filter(item => item.secondColumnVal !== fields['staff'].type);
                this.setFilter(filtered);

                this.filters.push({
                    firstColumnVal: optionAnd.type,
                    secondColumnVal: fields['staff'].type,
                    thirdColumnOptions: fields['staff'].options,
                    thirdColumnVal: fields['staff'].options[0].type,
                    fourthColumnVal: this.$route.query?.personId,
                });
                this.setFilter(this.filters);
            }

            const query: Record<string, string> = {};
            if (pageNumber > 1) {
                query.pageNumber = pageNumber;
            }

            const specificationsString: string | null = filterToSpecificationsString(this.filters);
            if (specificationsString) {
                query.specifications = specificationsString;
            }
        },

        editDescriptionVideo() {
            this.isEditDescriptionVideo = true;
        },

        async getVideosByIds() {
            try {
                this.serverErrorMessage = null;
                const limit = this.perPage;
                const offset = this.currentPageState;
                const resp = await getVideosByIds(limit, offset, this.fromSearchVideoIds);
                this.videos = resp;
                this.videosCount = resp.length;

                await this.reInitUsersAccess();
            } catch (e) {
                console.log(e);
                this.serverErrorMessage = this.$t('unknown_error');
                this.videos = [];
            }
        },

        selectedSearchResultCallback(videoIds) {
            this.fullTextSearchMode = false;
            this.fromSearchVideoIds = videoIds;
            this.getVideosByIds(videoIds);
        },

        applyFilters() {
            this.toggleFiltersVisibility(false);
            if (this.fullTextSearchMode) {
                this.fullTextSearch();
            } else {
                this.setVideos([]);
                this.getVideos();
            }
        },

        createFilterForFirstColumn(searchStr) {
            const s = searchStr.trim();
            if (s) {
                const filter = JSON.parse(`{"firstColumnVal":"and","secondColumnVal":"name","thirdColumnOptions":[{"type":"contain","title":"Содержит"},{"type":"not_contain","title":"Не содержит"}],"thirdColumnVal":"contain","fourthColumnVal":"${s}"}`);
                this.fromSearchVideoIds = null;
                this.setFilter([filter]);
                this.activeFilterItem = null;
                this.getVideos();
            } else {
                this.fromSearchVideoIds = null;
                this.setFiltersRows([]);
                this.getVideos();
            }
        },

        resetSearchCallback() {
            this.fromSearchVideoIds = null;
            this.getVideos();
        },

        updateListVideo(id) {
            this.setVideos(this.videos.filter(video => video.id !== id));
            this.countVideosDeleted += 1;
            if (!this.videos.length && this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.isDeletedVideo = true;
            }
        },

        getTextSearchStr(text) {
            this.fullTextSearchStr = text;
        },

        async removeVideo(video: IVideoDto) {
            this.formData = {
                description: video.description,
                isProtected: video.isProtected,
            };
            this.selected_video = video;
            this.$refs.modalDelete.show();
            this.selected_videos = null;
        },

        async removeSelectedVideos(videos: IVideoDto) {
            this.selected_videos = videos;
            this.$refs.modalDelete.show();
        },

        async onDeleteVideo() {
            if (this.selected_videos?.length) {
                for (const video of this.selected_videos) {
                    if (!video.isProtected) {
                        try {
                            await updateVideo(video, this.formData);
                            await this.deleteVideo(video.id);
                            this.updateListVideo(video.id);
                            this.$refs.modalDelete.hide();
                            this.$refs.editDescriptionModal.hide();
                            this.$refs.videosList.selectedVideos = [];
                            this.$refs.videosList.selectedVideosAll = null;
                            this.$refs.videosList.isSelectedVideosAll = false;
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            }

            if (this.selected_video.id) {
                try {
                    await updateVideo(this.selected_video, this.formData);
                    await this.deleteVideo(this.selected_video.id);
                    this.updateListVideo(this.selected_video.id);
                    this.$refs.modalDelete.hide();
                    this.$refs.editDescriptionModal.hide();
                } catch (error) {
                    console.error(error);
                }
            }
        },

        toggleListView() {
            this.isToggleListView = !this.isToggleListView;
            localStorage.setItem('isToggleListView', this.isToggleListView);
        },

        getWidthTable(widthTable) {
            this.widthTable = widthTable;
        },

        handlerScrollVideosListByX() {
            if (!this.isMobile) {
                this.videosListScrollLeft = this.$refs.videosListHelper.scrollLeft;
            }
        },

        scrollingSecondaryScrollbar(scrollLeft: Number) {
            this.$refs.videosListHelper.scrollLeft = scrollLeft
        },

        async handlerScrollVideosListByY() {
            const container = this.$refs.scrollContent;
            this.isAtBottom = container.scrollTop + container.clientHeight === container.scrollHeight;

            if (this.isAtBottom && !this.isEmptyVideoData) {
                if (!this.isSorting && !this.fullTextSearchStr) {
                    this.setCurrentPage(this.currentPageState += 1);
                    await this.getVideos(false, true);
                } else {
                    this.isLoading = false;
                }

                if (this.fullTextSearchStr) {
                    this.setCurrentPage(this.currentPageState += 1);
                    this.fullTextSearch(true);
                }
            }
        },

        scrollFilterRow(direction) {
            const filterRow = this.$refs.filterRow;
            if (direction === 'next') {
                filterRow.scrollTo({
                    left: filterRow.scrollWidth - filterRow.clientWidth,
                    behavior: 'smooth',
                });
                this.showPrevButton = true;
            } else if (direction === 'prev') {
                filterRow.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
                this.showNextButton = false;
            }
        },

        handleScrollFilters() {
            const filterRow = this.$refs.filterRow;
            this.showNextButton = filterRow.scrollLeft + filterRow.clientWidth < filterRow.scrollWidth;
            this.showPrevButton = filterRow.scrollLeft > 0;
        },

        async parameterSorting(name: String) {
            this.isSorting = !this.isSorting;
            this.isLoading = true;
            const pageNumber = this.currentPage;
            // const pageSize = this.perPage;
            const pageSize = 1000;

            try {
                const res = await getSortedResults(pageNumber, pageSize, name, this.isSorting);
                this.setVideos(res.data);
            } catch (err) {
                console.log(err);
            } finally {
                this.isLoading = false;
            }
        },
    },
});
