import {Observable} from 'rxjs';
import {PermissionGrantInfoDto} from '@app/system/api/service-proxies/service-proxies';

let showToastErrorFunc;
let showUserActivityExpiredModalFunc;

export function setShowToastErrorHandler(func) {
    showToastErrorFunc = func;
}

export function setShowUserActivityExpiredModalHandler(func) {
    showUserActivityExpiredModalFunc = func;
}

export function showToastError(errorText: string) {
    showToastErrorFunc(errorText);
}

export function showUserActivityExpiredModal() {
    showUserActivityExpiredModalFunc();
}

export class XhrFactoryReact {

    private userActivityExpiredMs = 7200 * 1000;
    private refreshTokenMs = 3600 * 1000;

    // @ts-ignore
    private logoutTimer: NodeJS.Timer | null = null;

    constructor() {
    }

    build(): XMLHttpRequest {
        const xhr = new XMLHttpRequest();

        xhr.withCredentials = true;

        const $oidc = window['$oidc'];
        const accessToken = $oidc.accessToken || null;

        xhr.addEventListener('readystatechange', async () => {
            if (xhr.readyState === 1) {
                if (!accessToken) {
                    const accessWithoutAuthPaths = ['/links'];
                    if (!accessWithoutAuthPaths.some(path => window.location.pathname.includes(path))) {
                        $oidc.signIn();
                    }
                }

                if (accessToken) {
                    this.startLogoutTimer();
                    this.logoutOrUpdateUserActive();
                    this.refreshTokenIfExpired();
                    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                }

                const accessTicketId = window['$accessTicketId'];
                // const needAccessTicketApiPaths = ['/video/get-include'];
                const isNeedAccessTicketHeader = true; // needAccessTicketApiPaths.some(path => xhr.url.includes(path));

                if (accessTicketId && isNeedAccessTicketHeader) {
                    xhr.setRequestHeader('AccessTicket', accessTicketId);
                }
            }
        });

        xhr.addEventListener('load', async (event) => {
            const status: number | undefined = (event?.target as any)?.status;
            if (status === 401) {
                if (!window.location.pathname.includes('/links')) {
                    $oidc.signIn();
                }
            } else if (status > 399) {
                const xhrResponse = (event?.target as any);
                let errorText = 'Во время выполнения запроса произошла ошибка. Попробуйте повторить операцию.';
                xhrResponse.response.text().then(raw => {
                    const resp = JSON.parse(raw);
                    if (resp.error.message) {
                        errorText = resp.error.message;
                    } else if (status === 403) {
                        errorText = 'Произошла ошибка на стороне сервера. В доступе отказано.';
                    } else if (status === 404) {
                        errorText = 'Произошла ошибка на стороне сервера. Ресурс не найден.';
                    }
                    console.log(resp.error.message);
                }).catch(e => {
                    console.log(e);
                }).finally(() => {
                    if (showToastErrorFunc) {
                        showToastErrorFunc(errorText);
                    }
                });
            }
        });

        xhr.addEventListener('error', async (error) => {
            const errorText = 'Во время выполнения запроса произошла ошибка. Попробуйте повторить операцию.';
            if (showToastErrorFunc) {
                showToastErrorFunc(errorText);
            }
        });

        return xhr;
    }

    private startLogoutTimer(): void {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
        }
        this.logoutTimer = setTimeout(() => {
            this.showLogoutModal();
        }, this.userActivityExpiredMs);
    }

    private logoutOrUpdateUserActive(): void {
        const currentMs = new Date().getTime();
        const storageKey = 'lastActiveMs';

        const lastActiveMs: string | null = localStorage.getItem(storageKey);
        const expired = lastActiveMs && (currentMs - this.userActivityExpiredMs) > +lastActiveMs;

        if (expired) {
            this.showLogoutModal();
        } else {
            localStorage.setItem(storageKey, currentMs.toString());
        }
    }

    private refreshTokenIfExpired(): void {
        const $oidc = window['$oidc'];
        const currentMs = new Date().getTime();
        const storageKey = 'lastRefreshTokenMs';

        const lastRefreshTokenMs: string | null = localStorage.getItem(storageKey);
        const needRefresh = (currentMs - this.refreshTokenMs) > +(lastRefreshTokenMs || 0);

        if (needRefresh) {
            $oidc.refreshToken();
            localStorage.setItem(storageKey, currentMs.toString());
        }
    }

    private showLogoutModal() {
        const $oidc = window['$oidc'];

        if (showUserActivityExpiredModalFunc) {
            showUserActivityExpiredModalFunc();
        } else {
            $oidc.signOut();
        }
    }

}
