
import {defineComponent} from 'vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

import {VSelect} from "vuetify/components";
import {mapActions, mapGetters, mapMutations} from 'vuex';

import cloneDeep from 'lodash-es/cloneDeep';

// eslint-disable-next-line
import {currentLocale} from '@app/i18n';
import SaveFilterModal from '@app/modules/filter/blocks/SaveFilterModal.vue';
import {makeVideoFilterItems} from "@app/modules/video/video.api";
import {api} from "@app/system/api/api-services";
import {IFilterItemDto, IFilterSetDto} from '@app/system/api/service-proxies/service-proxies';
import {IFilterRow} from "@app/types";
import {getPersonDisplayLabel} from "@app/utils/helpers";

import {fields, fieldsDropdownFilter, optionAnd, optionOr,} from '../../utils/dictionary';

const defaultRowPrototype: IFilterRow = {
    // First column - and / or
    // Not actual for rows[0]
    firstColumnVal: optionAnd.type,
    // Second column - field type
    secondColumnVal: fields['date'].type,
    // Third column - field type option
    thirdColumnOptions: fields['date'].options,
    thirdColumnVal: fields['date'].options[0].type,
    // FourthColumn - field text value
    fourthColumnVal: null,
}

export default defineComponent({
    name: 'DropdownFilter',
    components: {
        SaveFilterModal,
        DatePicker,
        VSelect,
    },
    props: {
        filters: {
            type: Array,
            default: function () {
                return [defaultRowPrototype];
            },
        },
        defaultNumFilters: {
            type: Number,
            default: 0,
        },
    },
    data: function () {
        return {
            fields: fieldsDropdownFilter,
            rows: cloneDeep(this.filters?.length ? this.filters : [defaultRowPrototype]) as IFilterRow[],
            optionAnd,
            optionOr,
            currentLocale,
            filterData: {},
            isInvalidFilters: false,
            serverErrorMessage: null,
            // period: [],
        }
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),

        ...mapGetters('filter', {
            personsIsLoading: 'isLoading',
            recognizedPersons: 'recognizedPersons',
            videoModerators: 'videoModerators',
            personsCount: 'personsCount',
        }),

        /* Getters from Vuex */
        mobileVersion() {
            const innerWidth = window.innerWidth && document.documentElement.clientWidth ?
                Math.min(window.innerWidth, document.documentElement.clientWidth) :
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.getElementsByTagName('body')[0].clientWidth;

            return innerWidth < 755;
        }
    },
    watch: {
        filters() {
            this.rows = cloneDeep(this.filters?.length ? this.filters : [defaultRowPrototype]) as IFilterRow[];
        },

        currentPage: function () {
            this.getFilterData();
        },
    },
    async mounted() {
        await this.getFilterData();

        if (this.isGrantedPolicy('Recorder.Participant')) {
            await this.getRecognizedPersons();
            await this.getVideoModerators()
        }

        this.validate();
    },

    methods: {
        ...mapActions('persons', {
            personsGetList: 'getList',
        }),
        ...mapActions('filter', {
            getRecognizedPersons: 'getRecognizedPersons',
            getVideoModerators: 'getVideoModerators'
        }),
        ...mapMutations('paginationVideos', {
            setCurrentPage: 'setCurrentPage'
        }),

        async addRow() {
            this.rows.push(cloneDeep(defaultRowPrototype));
            this.validate();
        },

        async getFilterData() {
            this.personsGetList({searchStr: ''});
            /*axiosVinteoRecordApiWithCredentials.get('filter/videos').then(result => {
                this.filterData = result.data;
            })*/
        },

        async deleteRow(index) {
            // VueBootstrap bug fix: if user click on the button of the last element of the this.rows,
            // then dropdown-filter will close. We don't need this behavior.
            setTimeout((scope) => {
                scope.rows.splice(index, 1);
                this.validate();
            }, 0, this);
        },

        async openSaveFilterModal() {
            this.$refs.saveFilterModal.show();
        },

        async save(name: string) {
            this.serverErrorMessage = null;

            const items: IFilterItemDto[] = makeVideoFilterItems(this.rows).map(item => ({
                ...item,
                specValue: JSON.stringify(item.specValue as unknown),
            }));

            const filterDto: IFilterSetDto = {
                title: name,
                targetEntityId: 'd0f5762e-4e50-4d6a-8edb-da67364f0614',
                isDefault: false,
                isStatic: false,
                isPublic: false,
                isHidden: false,
                items,
                // ownerId: '',
            } as any;

            try {
                const savedFilterDto = await api.filterSetServiceProxy.savePost('1.0', filterDto as any).toPromise();
                await this.apply(undefined, savedFilterDto);
                this.$refs.saveFilterModal.hide();
            } catch (e) {
                this.serverErrorMessage = e.error?.message || 'Во время выполнения запроса произошла ошибка.';
            }
        },

        async apply(event, filterDto: IFilterSetDto | undefined) {
            this.$emit('setFilters', this.rows);
            this.$emit('callback');
            this.$emit('setDefaultNumFilters', this.rows.length);

            if (filterDto) {
                this.$emit('saveFilterDto', filterDto);
            }

            // this.$refs.dropdown.hide(true);
            await this.getFilterData();
            this.setCurrentPage(1)
        },

        async clear() {
            this.rows = [cloneDeep(defaultRowPrototype)];
            this.$emit('setDefaultNumFilters', 0);
            this.$emit('setFilters', []);
            this.$emit('callback');
            this.validate();
        },

        // Set options by field key
        async changeSecondColumnVal(row) {
            this.validate();

            const key = row.secondColumnVal;
            row.thirdColumnOptions = fields[key].options;
            row.thirdColumnVal = fields[key].options[0].type;
            row.fourthColumnVal = null;
        },

        // eslint-disable-next-line no-unused-vars
        changeThirdColumnVal(row) {
            this.validate();
        },

        // eslint-disable-next-line no-unused-vars
        changeFourthColumnVal(row) {
            this.validate();
        },

        validate() {
            this.isInvalidFilters = this.rows?.some(row => {
                const {secondColumnVal: type, thirdColumnVal: operator, fourthColumnVal: value} = row;
                return type === null || operator === null || value === null || value === '';
            });
        },

        async setOperation(item) {
            this.rows.forEach((el) => {
                el.firstColumnVal = item;
            });
        },

        getPersonDisplayLabel(person) {
            return getPersonDisplayLabel(person);
        },

        getModeratorDisplayLabel(moderator) {
            return moderator.fullName
        },
    },

});
