
import {defineComponent} from 'vue';
import {auto} from "@popperjs/core";
import {getWidthScreen} from "@app/utils/helpers";

export default defineComponent({
    name: "SearchRow",
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        recognized: {
            type: Boolean,
            default: null
        },
    },
    emits: ['getTextSearchStr', 'getFullTextSearchStr'],

    data() {
        return {
            searchStr: '',
        }
    },
    computed: {
        isMobile() {
            return getWidthScreen()
        }
    },
    watch: {
        searchStr() {
            this.$emit('getTextSearchStr', this.searchStr)
        },
        recognized() {
            this.searchStr = ''
        }

    },
    methods: {
        auto() {
            return auto
        },
        fullTextSearch() {
            this.$emit('getFullTextSearchStr')
        }
    }
})

