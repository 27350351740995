import {i18n} from '@app/i18n';
import {api} from '@app/system/api/api-services';
import {
    SpecificationParameters,
} from '@app/system/api/service-proxies/service-proxies';

function initialState() {
    return {
        isLoading: false,
        isServerError: false,
        serverErrorMessage: null,
        auditLogsList: null,
        perPage: 100,
        currentPage: 1,
        logsCount: 0,

        searchStr: '',
        parameterName: null,
    };
}

const getters = {
    isLoading(state) {
        return state.isLoading;
    },

    isServerError(state) {
        return state.isServerError;
    },

    serverErrorMessage(state) {
        return state.serverErrorMessage;
    },

    perPage(state) {
        return state.perPage;
    },

    currentPage(state) {
        return state.currentPage;
    },

    auditLogsList(state) {
        return state.auditLogsList;
    },
    logsCount(state) {
        console.log('state.logsCount', state.logsCount);
        return state.logsCount;
    },
};

const actions = {

    async getAuditLogs({commit, state}, sortingDirection) {
        commit('setLoading');
        const pageSize = state.perPage;
        const pageNumber = state.currentPage;
        const searchStr = state.searchStr;
        const parameterName = state.parameterName;

        const expandedSearch  = new SpecificationParameters({
            specId: 'FBE8E62F-3486-4391-A023-DE079080F022',
            parameters: {value: `%${searchStr}%`},
        });
        const expandedSearchInclude  = new SpecificationParameters({
            specId: 'F4D286FE-3B5B-448B-B694-88DAE01F2446',
            parameters: null,
        });
        const sortByParameterName = new SpecificationParameters({
            specId: '',
            parameters: {isDescending: sortingDirection},
        });

        const actions = new SpecificationParameters({
            specId: 'F4D286FE-3B5B-448B-B694-88DAE01F2446',
            parameters: null,
        });
        const entityChanges = new SpecificationParameters({
            specId: '09F80E3C-18D2-4165-A2FC-80F0800B84A5',
            parameters: null,
        });
        const filterByUrlOne = new SpecificationParameters({
            specId: '836B31B5-4FC3-4CFB-8BF3-3B6337C16600',
            parameters: {
                "value": "Ntrnx.Recorder.Videos.VideoController",
            }
        });
        const filterByUrlTwo = new SpecificationParameters({
            specId: '621055DF-439F-4631-95BF-C19D177C1BA8',
            parameters: {
                "value": "%/api/recorder/video/page%",
                "isNot" : true
            }
        });

        if (parameterName === 'action') sortByParameterName.specId = 'B35A6784-D165-482F-9445-C52B23D3FE73';
        if (parameterName === 'date') sortByParameterName.specId = 'E668BD0F-04B9-4832-BF83-84552B0A9592';
        if (parameterName === 'video') sortByParameterName.specId = 'E668BD0F-04B9-4832-BF83-84552B0A9592';
        if (parameterName === 'user') sortByParameterName.specId = '3926B705-0CE7-4044-9329-4FB8585236FA';
        if (parameterName === 'ip') sortByParameterName.specId = '4E536ABE-C1EF-4D23-99A1-C0583BC258C4';
        if (parameterName === 'browserInfo') sortByParameterName.specId = 'BD0D91B4-5434-4488-9C0A-1430F2F8955B';

        const specifications = [
            actions,
            entityChanges,
            filterByUrlOne,
            filterByUrlTwo
        ];

        if (searchStr.length) {
            specifications.push(expandedSearch);
            specifications.push(expandedSearchInclude);
        }
        if (parameterName) {
            specifications.push(sortByParameterName);
        }
        return await api.auditLogServiceProxy.page(pageNumber, pageSize, true, '1.0', specifications).toPromise()
            .then(resp => {
                    // console.log('resp getAuditLogs ===>', resp);
                    commit('setLoaded');
                    commit('setLogs', resp);
                    commit('setLogsCount', resp.totalCount);
                },
            )
            .catch((err) => {
                commit('setLoaded');
                commit('setServerError', err);
            });

    },
};

/* eslint-disable no-param-reassign */
const mutations = {
    setLoading(state) {
        state.isLoading = true;
        state.isServerError = false;
        state.serverErrorMessage = null;
    },

    setLoaded(state) {
        state.isLoading = false;
    },

    setServerError(state, err) {
        state.isServerError = true;
        if (err.response && err.response.data && err.response.data.error) {
            state.serverErrorMessage = err.response.data.error;
        } else {
            state.serverErrorMessage = i18n.global.t('unknown_error');
        }
    },

    setPaginationInfo(state, paginationInfo) {
        state.perPage = paginationInfo.perPage;
        state.currentPage = paginationInfo.currentPage;
    },

    setSearchStr(state, searchStr) {
        state.searchStr = searchStr;
    },

    setLogs(state, logs) {
        state.auditLogsList = logs;
    },

    resetSearchStr(state) {
        state.searchStr = '';
    },
    setLogsCount(state, count) {
        state.logsCount = count;
    },
    setParameterName(state, type) {
        state.parameterName = type
    }
};

/* eslint-enable no-param-reassign */
export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
