
import {useVuelidate} from '@vuelidate/core'
import {minLength, required} from '@vuelidate/validators'
import {defineComponent} from 'vue';
import Modal from "@app/components/ui-kit/molecules/Modal.vue";

export default defineComponent({
    name: 'SaveVideoTrim',
    components: {
        Modal,
    },
    props: {
        video: {
            type: Object,
            required: true
        },
        showModal: {
            type: Boolean,
            default: false
        },
        newVideo: {
            type: Boolean,
            default: false
        }
    },

    setup() {
        return {v$: useVuelidate()}
    },

    data() {
        return {
            id: 'save-video-trim-modal',
            form: {
                description: '',
            },
            modalShow: false,
            videoNameStatus: true,
            confidential: false,
        }
    },
    computed: {
        disable() {
            return this.form.description.length >= 2 && this.videoNameStatus
        },
    },
    validations() {
        return {
            form: {
                description: {required, minLength: minLength(2)},
            },
        }
    },

    methods: {
        show() {
            this.modalShow = true;
            this.v$.$reset()
        },

        hide() {
            this.modalShow = false;
        },

        async submitForm() {
        },

        resetForm() {
            if (!this.newVideo) {
                this.form.description = this.video.description
            } else {
                this.form.description = ''
            }
        },

        resetModal() {
            this.resetForm()
        },

        async closeModal() {
            this.hide();
        },
    }
});
