
import {defineComponent} from 'vue';
import {mapActions, mapGetters} from 'vuex';

// import CopyVideoLinkBtn from "@app/modules/video/blocks/CopyVideoLinkBtn.vue";
import Note from '@app/modules/video/blocks/Note.vue';
import VideoInstanceMenu from '@app/modules/video/blocks/VideoInstanceMenu.vue';
import VideoRowUsersWithAccess from '@app/modules/video/blocks/VideoRowUsersWithAccess.vue';
import VideoUsersInVideoInstance from '@app/modules/video/blocks/VideoUsersInVideoInstance.vue';

export default defineComponent({
    name: 'VideoBlock',
    components: {
        VideoUsersInVideoInstance,
        VideoRowUsersWithAccess,
        VideoInstanceMenu,
        Note,
        // CopyVideoLinkBtn
    },
    props: {
        isLoading: {
            type: Boolean,
            default: true,
        },
        showMessage: {
            type: Boolean,
            default: false,
        },
        selectedTimeOfProtocol: {
            type: Boolean,
            default: true,
        },
        selectedTimeOfTimeline: {
            type: Boolean,
            default: true,
        },
        allUser: {
            type: Array,
            required: true,
        },
        usersAccess: {
            type: Object,
            required: true,
        },
        isModerator: {
            type: Function,
            required: true,
        },
        changePublicityCallback: {
            type: Function,
            required: true,
        },
        changeUserAccessOfVideo: {
            type: Function,
            required: true,
        },
        editPersonCallback: {
            type: Function,
            required: true,
        },
        removeVideo: {
            type: Function,
            required: true,
        },
        jwt: {
            type: Function,
            required: true,
        },
        getVideoHeight: {
            type: Function,
            required: true,
        },
        editDescription: {
            type: Function,
            required: true,
        },
    },
    emits: [
        'getHeightSkeletonProtocol',
        'getVideoInstanceBg',
        'toggleVideoTrimModalVisibility',
        'videoCurrentTime',
        'getVideoSectionHeight',
    ],
    data() {
        return {
            featureVideoTrim: false,
            videoEl: null,
            currentTime: null,
            // messageSuccessCopyVideoUrl: false
            videoPlayerCurrentTime: 0,
            videoDataLocalstorage: null,
        };
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),
        ...mapGetters('video', [
            'video',
        ]),
    },

    watch: {
        selectedTimeOfProtocol() {
            this.setCurrentTime(this.selectedTimeOfProtocol);
        },
        selectedTimeOfTimeline() {
            this.setCurrentTime(this.videoPlayerCurrentTime);
        },
    },
    updated() {
        this.windowWidth = window.innerWidth;
        const videoInstanceBg = this.$refs.videoInstanceBg?.clientHeight;
        this.$emit('getVideoInstanceBg', videoInstanceBg);

        const videoTrimFlag = localStorage.getItem('video_trim');
        videoTrimFlag === 'enable' ? this.featureVideoTrim = true : this.featureVideoTrim = false;

        this.$emit('getVideoSectionHeight', this.$refs.videoSection.clientHeight);

        this.videoDataLocalstorage.forEach(item => {
            if (item.video_id === this.video?.id) {
                this.videoPlayerCurrentTime = item.recentViewingTimes;
            }
        });
        if (this.videoEl) {
            this.videoEl.currentTime = this.videoPlayerCurrentTime;
        }
    },
    mounted() {
        this.$emit('getHeightSkeletonProtocol', this.$refs.skeletonPlayer?.clientHeight);
        this.videoDataLocalstorage = JSON.parse(localStorage.getItem('videos_data')) || [];

        setInterval((scope) => {
            if (scope.videoEl && scope.videoEl.duration) {
                scope.videoPlayerCurrentTime = scope.videoEl.currentTime || 0;
                scope.videoPlayerDuration = scope.videoEl.duration;

                this.currentTime = scope.videoEl.currentTime;
                this.$emit('videoCurrentTime', this.currentTime);

                this.videoDataLocalstorage.forEach(item => {
                    if (item.video_id === this.video.id) {
                        item.recentViewingTimes = this.videoEl.currentTime;
                    }
                });
                localStorage.setItem('videos_data', JSON.stringify(this.videoDataLocalstorage));
            }
        }, 25, this);
    },
    methods: {
        ...mapActions('video', [
            'playerOn',
            'playerOff',
        ]),

        // TODO: временно не используется
        /*showMessageSuccessCopyVideoUrl() {
            this.messageSuccessCopyVideoUrl = true
            setTimeout(() => {
                this.messageSuccessCopyVideoUrl = false
            }, 3000)
        },*/

        getVideoSrc() {
            return this.video?.preparedFileData?.url || '';
        },
        playBtnClick() {
            this.playerOn();
        },
        stopBtnClick() {
            this.playerOff();
        },
        onVideoPlayerReady(event) {
            this.videoEl = event.video;
        },
        toggleShowVideoTrimModal() {
            this.$emit('toggleVideoTrimModalVisibility');
        },
        updateVideoProgressBar() {
            this.videoProgressBarWidth = this.videoPlayerCurrentTime * 100 / this.videoPlayerDuration + '%';
            this.videoCurrentTimeStr = this.getTime(this.videoPlayerCurrentTime);
        },
        setCurrentTime(currentTime: number) {
            this.videoDataLocalstorage.forEach(item => {
                if (item.video_id === this.video.id) {
                    item.recentViewingTimes = currentTime;
                }
            });

            this.videoPlayerCurrentTime = currentTime;
            this.currentTime = currentTime;
            this.videoEl.currentTime = currentTime;
        },
    },
});
