
import {useVuelidate} from '@vuelidate/core'
import {minLength, required} from '@vuelidate/validators'
import {defineComponent} from 'vue';
import {mapGetters} from "vuex";

import Modal from "@app/components/ui-kit/molecules/Modal.vue";
import {updateVideo} from '@app/modules/video/video.api';

export default defineComponent({
    name: 'EditDescriptionModal',
    components: {
        Modal,
    },
    props: {
        video: {
            type: Object,
            required: true
        }
    },
    emits: ['videosCallback', 'updateListVideo', 'removeVideo', 'editDescriptionVideo'],

    setup() {
        return {v$: useVuelidate()}
    },

    data() {
        return {
            id: 'edit-description-modal',
            form: {
                description: '',
                isProtected: false,
            },
            modalShow: false,
            videoNameStatus: true,
            isBtnDelete: true
        }
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            userEmail: 'userEmail',
        }),

        isSubmitAvailable() {
            return this.form.description.length >= 2 && this.videoNameStatus
        },
        isDisable() {
            return this.form.isProtected
        }
    },

    validations() {
        return {
            form: {
                description: {required, minLength: minLength(2)},
            },
        }
    },

    watch: {
        modalShow() {
            this.resetModal();
        },
    },

    methods: {

        show() {
            this.modalShow = true;
            this.v$.$reset()
        },

        hide() {
            this.modalShow = false;
        },

        async submitForm() {
            await updateVideo(this.video, {description: this.form.description, isProtected: this.form.isProtected})
                .then(() => {
                    this.$emit('editDescriptionVideo', );
                    this.$emit('videosCallback');
                    this.hide();
                });
        },

        async resetForm() {
            this.form = {
                description: this.video.description,
                isProtected: this.video.isProtected
            };
        },

        async resetModal() {
            await this.resetForm();
        },

        async closeModal() {
            this.hide();
        },

        async removeVideo() {
            const newVideoData = {...this.video, description: this.form.description, isProtected: this.form.isProtected}
            this.$emit('removeVideo', newVideoData)
        }
    }
});
