
export default {
    name: "UiErrorSnackbar",
    data() {
        return {
            showSnackbar: false,
            message: '',
            color: 'error',
            icon: 'mdi-alert-outline',
            timer: 5000
        }
    },
    methods: {
        show(data) {
            this.message = data.message || 'Unknown error';
            this.timer = data.timer || 5000;
            this.showSnackbar = true;
        },

        onClickClose(event: Event) {
            event.stopPropagation();
            event.preventDefault();

            this.showSnackbar = false;
        }
    }
}
