

import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import {getWidthScreen} from '@app/utils/helpers';

export default defineComponent({
    name: 'DropdownMenuInVideos',
    props: {
        isLoading: {
            type: Boolean,
            default: null,
        },
        dropdownPosition: {
            type: Object,
            required: true,
        },
        video: {
            type: Object,
            required: true,
        },
    },
    emits: [
        'edit',
        'remove',
    ],

    data() {
        return {
            dropdownVisible: false,
        };
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            isAdmin: 'isAdmin',
            userEmail: 'userEmail',
        }),

        isMobile() {
            return getWidthScreen();
        },

        isEditDisable() {
            return !this.isGrantedPolicy('Recorder.Video.Update', false, this.video.permissions) || this.isLoading;
        },

        isDeleteDisable() {
            return !this.isGrantedPolicy('Recorder.Video.Delete', false, this.video.permissions) || this.isLoading;
        },
    },

    methods: {
        hideDropdown() {
            this.dropdownVisible = false;
        },

        showDropdown() {
            this.dropdownVisible = true;
        },

        edit() {
            if (this.isEditDisable) {
                return;
            }

            this.hideDropdown();
            this.$emit('edit');
        },

        remove() {
            if (this.isDeleteDisable) {
                return;
            }

            this.hideDropdown();
            this.$emit('remove');
        },
    },
});
