export default {

    auth: {
        signin_title: 'Вход',
        signup_title: 'Регистрация',
        login_label: 'Логин',
        login_placeholder: 'Ваш логин',
        password_label: 'Пароль',
        forgot_password: 'Забыл пароль',
        signin_btn: 'Войти',
        footer_all_rights_tip: 'Все права защищены',
        footer_personal_data_tip: 'Соглашение на обработку персональных данных',
        footer_privacy_policy: 'Политика конфиденциальности',
        oauth_fail_msg: 'Получен невалидный ответ от Idenity Server. Попробуйте авторизоваться повторно',
        logouted_msg: 'Вы вышли из системы.',
        login_again: 'Войти снова',
    },

    /* Titles */
    page_titles: {
        main_page: 'Главная страница',
        video: 'Видео',
        staff: 'Участники',
        unrecognized: 'Нераспознанные',
        broadcasts: 'Трансляции',
        roles: 'Роли',
        users: 'Пользователи',
        error: 'Ошибка',
        journal: 'Журнал',
        system: 'Система',
        vinteo_server_settings: 'Настройки сервиса Vinteo',
        files_auto_delete_settings: 'Настройки политик автоудаления файлов',
        updates: 'Обновления',
        vsp_settings: 'Настройки сервиса VSP',
        network_settings: 'Настройки сети',
        copy_video_inner_link: 'Копировать внутреннюю ссылку',
        copy_video_public_link: 'Копировать публичную ссылку',
        video_access_denied: 'У вас нет доступа к данному видео',
        participant_not_recognized: 'Участник не распознан',
        action_logs: 'Журналы действий',

    },

    modal_titles: {
        new_user: 'Новый пользователь',
        delete_role: 'Удаление роли',
        disabling_deletion_protection: 'Отключение защиты от удаления',
        delete_user: 'Удаление пользователя',
        delete_person: 'Удаление участника',
        delete_access_url: 'Удаление доступа по ссылке',
        delete_access_person: 'Удаление доступа пользователя',
        new_person: 'Новый участник',
        united_persons: 'Объединение участников',
        united_persons_subtitle: 'Выберите основного участника, с которым будут объединены дубликаты',
        video_upload: 'Загрузить видео',
        video_description: 'Редактирование видео',
        save_filter: 'Сохранить фильтр',
        edit_filter: 'Редактировать фильтры',
        select_filter: 'Выбрать фильтр',
        delete_video: 'Удаление видео',
        add_person: 'Добавить участника',
        add_role: 'Добавить роль',
        access_editing: 'Редактирование доступа',
        role_editing: 'Редактирование роли',
        employee_editing: 'Редактирование участника',
        add_user: 'Добавить пользователя',
        user_editing: 'Редактирование пользователя',
        access_video: 'Предоставление доступа к видео',
        ended_session_message: 'Ваша сессия была автоматически завершена в связи с длительным периодом не активности',

        message_for_ldap_users: '* Это - внешняя учётная запись. Смена пароля для внешних учётных записей производится в том сервисе, где такая запись была создана',

        // Editing user roles
        role_name: 'Наименование роли',
        general_rights: 'Общие права',
        editing_user_roles: 'Редактирование ролей пользователей',
        editing_system_settings: 'Редактирование настроек системы',
        viewing_system_logs: 'Просмотр логов работы системы',
        video_rights: 'Права на видео',
        view: 'Просмотр',
        editing: 'Редактирование',
        deleting: 'Удаление',
        providing_public_link: 'Предоставление публичной ссылки',
        condition: 'Условие',
        apply_filter: 'Применить фильтр',
        add_another_condition: 'Добавить еще условие',
        filter_message: 'Видео, удовлетворяющие условиям, отсутствуют',
        saved_filters: 'Сохраненные фильтры',
        identity_role_assign: 'Привязка к системным ролям',
        identity_roles: 'Системные роли',

        // Errors validations
        passwordLength: 'Не менее 8 символов',
        lowercaseLetters: 'Буквы нижнего регистра (от a до z)',
        uppercaseLetters: 'Буквы верхнего регистра (от A до Z)',
        numbers: 'Цифры (от 0 до 9)',
        specialCharacters: 'Спецсимволы (например: !, $, #, %)',
        invalidEmail: 'Укажите действительный адрес электронной почты',
        minLength: 'Не менее 2 символов',
        ip_address: 'Укажите действующий IP адрес',
        incorrect_data: 'Некорректные данные',
        illegalCharacters: 'Недопустимые символы',
        firstAndLastNameRequired: 'Введите имя и фамилию',
        minLength_maxLength: 'Не менее 2 и не более 128 символов',
        password_policy: 'В соответствии с требованиями парольной политики',


        // Subtitles
        participant_number: 'Номер участника',
    },

    system_title: {
        address: 'Адрес',
        address_placeholder: 'Введите адрес',
        ip_address: 'IP адрес',
        ip_address_placeholder: 'Введите IP адрес',
        login: 'Логин',
        password: 'Пароль',
        hide_show: 'Показать/Скрыть',
        apply_changes: 'Применить изменения',
        upload_update_file: 'Загрузить файл с обновлениями',
        timezone: 'Часовой пояс сервера Vinteo',
        ignore_ssl_errors: 'Игнорировать ошибки проверки SSL-сертификата',
        is_request_logging_enabled: 'Логирование API запросов к серверу Vinteo',
        is_enable_vinteo_integration: 'Включить интеграцию с Vinteo',
        minimum_length_of_processed_video: 'Минимальная длина обрабатываемого видео, секунд',
        maximum_length_of_processed_video: 'Максимальная длина обрабатываемого видео, секунд',
        interval_for_checking_new_videos_in_vinteo: 'Интервал проверки новых видео в Vinteo',
        interval_of_polling_ml_service_about_processing_status: 'Интервал опроса ML-сервиса о статусе обработки',
        is_process_vinteo_audio_protocols: 'Обрабатывать аудио-стенограммы Vinteo',
        processed_videos_preserve_interval: 'Удалять обработанные видео через (дней)',
        processed_videos_preserve_interval_day: 'День недели (1-7)',
        is_auto_delete_processed_videos: 'Автоматическое удаление обработанных видео из Vinteo',
    },

    table_titles: {
        login: 'Логин',
        fullname_short: 'ФИО',
        first_name: 'Имя',
        last_name: 'Фамилия',
        date: 'Дата',
        duration: 'Продолжительность',
        name: 'Название',
        staff: 'Участники',
        access: 'Доступ',
        public_link: 'Публичная ссылка',
        follow: 'Перейти',
        mark_to_delete: 'Метка удаления',
        mark_to_save: 'Запрет удаления',
        delete: 'Удалить',
    },

    journal_table_titles: {
        date: 'Дата',
        caller: 'Инициатор',
        action: 'Действие',
        video_id: 'ID Видео',
    },

    titles: {
        avatar: 'Аватар',
        fullname: 'ФИО',
        first_name: 'Имя',
        middle_name: 'Отчество',
        last_name: 'Фамилия',
        login: 'Логин',
        email: 'Email',
        password: 'Пароль',
        information: 'Информация',
        total: 'Всего',
        staff: 'Участники',
        phrases: 'Фразы',
        conferences: 'Конференции',
        name: 'Название',
        personal_data: 'Личные данные',
        person: 'Персона',
        note: 'Заметки',
        user: 'Пользователь',
        vinteo_id: 'ID абонента модератора конференции (для Vinteo)',
        protocol: 'Стенограмма',
        chat: 'Чат',
        participants: 'Участники',
        files: 'Файлы',
        ssl_certificate: 'SSL сертификат',
        ssl_key: 'SSL ключ',
        update_file: 'Файл с обновлениями',
        staff_on_video: 'Участники на видео',
        users_with_access: 'Пользователи с доступом',
        timeline: 'Таймлайн',
        accesses: 'Доступы',
    },

    buttons_common: {
        select_file: 'Выбрать файл',
        generate: 'Сгенерировать',
        add: 'Добавить',
        save: 'Сохранить',
        copy: 'Cкопировать',
        upload: 'Загрузить',
        exit: 'Выход',
        cancel: 'Отменить',
    },

    /* Buttons */
    buttons: {
        create_user: 'Создать пользователя',
        create_person: 'Создать участника',
        save: 'Сохранить',
        cancel: 'Отмена',
        upload: 'Загрузить',
        unite: 'Объеденить',
        share: 'Поделиться',
        delete: 'Удалить',
        deleted: 'Удалено',
        disconnect: 'Отключить',
        disable_delete: 'Запретить удаление',
        details: 'Подробнее',
        logout: 'Выйти',
        enable_access_to_all: 'Разрешить доступ всем',
        copy_link_for_video: 'Копировать ссылку',
        profile: 'Профиль',
        goTo: 'Перейти',
        provide: 'Предоставить',
        download_logs: 'Скачать логи',
    },

    // Placeholders
    placeholders_common: {
        choose_file: 'Выберите файл...',
        drop_placeholder: 'Перетащите файл сюда...',
        no_file_selected: 'Файл не выбран',
        search: 'Поиск',
        staff_search: 'Поиск участника',
        search_video: 'Поиск видео',
        user_search: 'Поиск пользователя',
        name: 'Наименование (не обязательно)',
        name2: 'Наименование',
        validity_period: 'Срок действия',
        search_log: 'Поиск лога',
        access_name_or_email: 'ФИО или email'
    },

    /* Left navs */
    navs: {
        video: 'Видео',
        staff: {
            general: 'Участники',
            recognized: 'Распознанные',
            unrecognized: 'Нераспознанные',
        },
        broadcasts: 'Трансляции',
        users: 'Пользователи ',
        roles: 'Роли',
        journal: 'Журнал',
        system: 'Система',
        audit_logs: 'Журналы действий',
        by_link: 'По ссылке',
        by_email: 'По почте'
    },

    video_list: {
        protocol_records: 'Записи в стенограммах',
    },

    video_access_types: {
        all_users: 'Все зарегистрированные',
        all_allowed_users: 'Только приглашенные люди',
        public: 'Любой у кого есть ссылка',
    },

    /* Other */
    view_placeholder_text: 'Содержание здесь',
    not_found: 'Ничего не найдено',
    unknown_error: 'Неизвестная ошибка',
    empty_list: 'Пустой список',
    delete_role_text: 'Удалить роль',
    disable_deletion_protections: 'Отключить защиту от удаления у',
    delete_user_text: 'Удалить пользователя',
    delete_person_text: 'Удалить участника',
    delete_video_text: 'Удалить видео',
    delete_videos_text: 'Удалить',
    delete_user_text_info: 'Для удаления пользователя перейдите в интерфейс',
    delete_access: 'Вы уверены, что хотите удалить доступ пользователя к данному видео',

    alerts_common: {
        messageTitle: 'Сообщение',
        linkForTheVideoHaveBeenCopied: 'Ссылка на видео скопирована',
        something_wrong: 'Что-то пошло не так!',
        settings_saved: 'Настройки сохранены',
        invalid_parameter_format: 'Неверный формат параметров!',
        attention_server_will_restart_after_update: 'Внимание! После обновления сервер будет перезагружен.',
        file_must_have_a_extension: 'Файл должен иметь расширение {extension}',
        failed_to_download_update: 'Не удалось загрузить обновление!',
        update_was_downloaded_successfully: 'Обновление успешно загружено. Сервер будет перезапущен.',
        attention_overwriting_nginx_config_file: 'Внимание! Применение изменений приведёт к перезаписи конфигурационного файла Nginx, после чего сервер будет перезагружен.',
    },

    /* Dropdown filter */
    dropdown_filter: {
        // Container
        title: 'Фильтр',
        column: 'Колонка',
        add_filter: 'Добавить фильтр',
        filters: 'фильтров',
        apply: 'Применить фильтр',
        save: 'Сохранить фильтр',
        // Selects
        equal: 'Равно',
        not_equal: 'Не равно',
        before: 'До',
        after: 'После',
        contain: 'Содержит',
        not_contain: 'Не содержит',
        access_granted: 'Дан',
        access_not_granted: 'Не дан',
        present: 'Присутствует',
        not_present: 'Не присутствует',
        moderates: 'Модерирует',
        not_moderates: 'Не модерирует',
        there_is: 'Есть',
        there_not_is: 'Нет',
        period: 'Период',

        link_for_all: 'Доступ у любого пользователя по ссылке',
        link_for_all_users: 'Доступ у любого зарегистрированного пользователя по ссылке',
        link_for_allowed_users: 'Доступ у пользователя из списка',

        and: 'И',
        or: 'Или',
        // Field names
        date: 'Дата',
        time: 'Время',
        name: 'Название',
        conference_name: "Название конференции",
        conference: 'Конференция',
        staff: 'Участники',
        access: 'Доступ',
        public_link: 'Публичная ссылка',
        caller: 'Инициатор',
        action: 'Действие',
        video: 'ID Видео',
        moderator: 'Модератор',
    },

    /* Video instance page */
    video_instance: {
        protocol: 'Стенограмма',
        notes: 'Заметки',
        duration: 'Длительность',
        employees: 'Участников',
        public_link: 'Пуб. ссылка',
        typing_notes: 'никто не вводит текст | 1 пользователь вводит текст | {n} пользователя вводят текст | {n} пользователей вводят текст',
        protocol_search_placeholder: 'Поиск по стенограмме',
        unrecognized: 'Нераспознанный',
    },

    protocol: {
        protocol_editing: 'Редактировать стенограмму',
        cancel: 'Отменить',
        save_changes: 'Сохранить изменения',
        delete_message: 'Удалить запись',
    },

    accesses: {
        add: 'Добавить',
    },

    // enums
    enums: {
        // Действия
        actions: {
            access_granted: 'Доступ разрешен',
            access_denied: 'Доступ запрещен',
            made_public: 'Публичный доступ разрешен',
            made_private: 'Публичный доступ запрещен',
            video_made_public: 'Разрешен доступ для любого пользователя с ссылкой',
            video_made_available_for_all_users: 'Разрешен доступ для всех зарегистрированных пользователей',
            video_made_available_for_allowed_users: 'Публичный доступ запрещен',
            watch_video: 'Посмотрел видео',
            delete_video: 'Удалил видео',
            video_appeared: 'Видео появилось',
        },
    },

    tooltips: {
        logout: 'Выйти',
        persons_on_video: 'Участники на видео',
        users_with_access: 'Пользователи с доступом',
        edit: 'Редактировать',
        delete: 'Удалить',
        available_videos: 'Доступные видео',
        add_person: 'Добавить пользователя',
        videos_with_person: 'Видео с участником',
        update: 'Обновить',
        not_recognized: 'Не опознан',
        copy: 'Копировать',

        event_attending_the_meeting: 'Есть на встрече',
        event_talk: 'Говорит',
        event_present_on_the_video: 'Присутствует на видео',
        event_positive_emotion: 'Радость',
        event_fear_emotion: 'Страх',
        event_negative_emotion: 'Грусть',
        event_surprise_emotion: 'Эмоция сюрприз',
        event_disgust_emotion: 'Неприязнь',
        cancel_save: 'Выйти без сохранения',
        save_protocol: 'Сохранить стенограмму',
        video_trim: 'Обрезать видео',
        access_blocked_delete: 'Для ограничения доступа пользователя к данному видео необходимо забрать роль, разрешающую доступ к этому видео',

    },

    // Search
    search: {
        person: 'Нет персон | Персона | Персоны',
        text: 'Нет фраз | Фраза | Фразы',
        note: 'Нет заметок | Заметка | Заметки',
        user: 'Нет пользователей | Пользователь | Пользователи',
        video: 'Нет видео | Видео | Видео',
        role: 'К сожалению, роль не найдена. Измените условия поиска.',
        userSearch: 'К сожалению, пользователь не найден. Измените условия поиска.',
        participant: 'К сожалению, участник не найден. Измените условия поиска.',
        access_not_found: 'Доступ не найден. Измените условия поиска.',
        access_loaded: 'Доступы отсутствуют',
        logs: 'К сожалению, логи не найдены. Измените условия поиска.',
    },

    file_uploads: {
        select_file_tip1: 'Выберите файл',
        select_file_tip2: 'или перенесите файл сюда',
        drop_file_note_zip: 'Файл должен иметь расширение .zip',
    },

    video_trim: {
        trim: 'Обрезать',
        cut_out: 'Вырезать',
        apply: 'Применить',
        save: 'Сохранить',
        saving: 'Сохранение',
        out_no_save: 'Выйти без сохранения',
        save_as_new: 'Сохранить как новое видео',
        title: 'Название',
        specify_the_name: 'Укажите название',
        room_number: 'Номер комнаты',
        record_time: 'Время записи',
        confidentially: 'Конфедициально',
        cancel: 'Отмена',
        massage: 'Видео слишком короткое для редактирования',
    },

    // Instruction
    instruction_title: 'Распознавание участников отключено',
    instruction_description: 'Для включения распознавания необходимо',
    instruction_step_one: 'Перейти в раздел',
    instruction_step_two: 'Переключиться на настройку сервиса VPS"',
    instruction_step_three: 'Выбрать пункт "Распознавать участников используя GPU", либо "Распознавать участников, используя CPU (медленно)"',
    instruction_step_four: 'Применить изменения',
    instruction_system_link: 'Система',

    errors: {
        access_denied: 'Доступ запрещен',
        not_found: 'Страница не найдена',
        filters_same_name: 'Невозможно сохранить фильтры с одинаковым названием',
    },

    // Users roles
    roles: 'Роли',
    role: 'Роль',
    users: 'Пользователи',
    access_roles: 'Роли доступа',
    moderators: 'Модераторы',

    permissions: {
        'RecorderUserAccount': 'Просмотр',
        'RecorderUserAccountCreate': 'Создание',
        'RecorderUserAccountUpdate': 'Обновление',

        'RecorderAccessGroup': 'Просмотр',
        'RecorderAccessGroupCreate': 'Создание',
        'RecorderAccessGroupUpdate': 'Обновление',
        'RecorderAccessGroupDelete': 'Удаление',

        'RecorderVideo': 'Просмотр',
        'RecorderVideoCreate': 'Создание',
        'RecorderVideoUpdate': 'Обновление',
        'RecorderVideoDelete': 'Удаление',
        'RecorderVideoEditing': 'Редактирование',
        'RecorderVideoManagePermissions': 'Редактирование доступов',
        'RecorderVideoUpload': 'Загрузка',
        'RecorderVideoEventUpdate': 'Редактирование стенограммы',

        group: {
            'RecorderUserAccount': 'Пользователи',
            'RecorderAccessGroup': 'Роли',
            'RecorderVideo': 'Видео',
        },
    },

    checkbox_labels: {
        not_automatically_delete: 'Защитить от удаления',
        indefinitely: 'Бессрочно',
    },
};
