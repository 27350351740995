
import {defineComponent} from 'vue';

import SaveVideoTrim from "@app/modules/video/blocks/SaveVideoTrim.vue";
import {getTime} from '@app/utils/helpers';
import SwitchBox from "@app/components/ui-kit/molecules/SwitchBox.vue";

export default defineComponent({
    name: 'VideoTrimModal',
    components: {
        SwitchBox,
        SaveVideoTrim
    },
    props: [
        'video',
        'showModal',
        'forcedShowModal'
    ],

    data() {
        return {
            state: {
                widthSeparatorsLine: 2,
                subsegmentsCount: 5,
                unitRem: 8,
                original_width: 0,
                original_x: 0,
                mouse_x: 0,
                paddingsOfTimelineWrap: 16,

                // Sticks
                isLeft: false,
                isRight: false,
                isLeftStick: false,
                isRightStick: false,
                isActiveResize: false,
                isLeftStickActive: false,
                isRightStickActive: false,

                // Stick masks
                widthBackdropLeft: null,
                widthBackdropRight: null,

                newVideo: false,

                // Player
                videoEl: null,
                isVideoActive: true,

                // Timeline
                currentTimeStart: 0,
                currentTimeEnd: null,
                totalSeconds: null,
                widthSegmentItemHeader: null,
                gapOffset: null,
                // widthTimelineRow: null,
                widthStickItem: null,
                offsetLeftRightStick: null,

                // Switcher
                isTrim: true,
                isCutOut: false,

                // Tooltips timeStart, timeEnd
                isTooltipLeftActive: false,
                isTooltipRightActive: false,
                isDropdownVisible: false,

                // CutterFrame
                width: 0,
                left: 0,
                currentWidth: 0,
                currentLeft: 0,

                // Scroll
                scroll_X: 0,
                isScrollTouch: false,
                // widthCustomScroll: null,
            },
            defaultState: null,

            currentScroll_X: 0,
            videoPlayerCurrentTime: 0,
            forcedRender: false,

            widthTimelineRow: null,
            widthCustomScroll: null,
        }
    },
    watch: {
        showModal() {
            this.getWidthSegmentItemTimeline()
        },
        videoPlayerCurrentTime() {
            this.updateVideoProgressBar()
        },
        currentScroll_X() {
            if (this.state.isScrollTouch && this.state.isVideoActive) {
                setInterval(() => {
                    this.videoPlayerCurrentTime = this.state.videoEl.currentTime || 0;
                }, 25);
                this.state.videoEl.currentTime = this.currentScroll_X / 20
            }
        },
    },
    created() {
        this.defaultState = Object.assign({}, this.state)
    },
    updated() {
        this.widthTimelineRow = this.$refs.timelineRow.clientWidth
        this.widthCustomScroll = this.$refs.timeline.offsetWidth
    },
    async mounted() {
        setInterval(() => {
            if (this.state.videoEl && this.state.videoEl.duration) {
                if (!this.state.isScrollTouch && this.state.isVideoActive) {
                    this.videoPlayerCurrentTime = this.state.videoEl.currentTime || 0;
                }
            }
        }, 25);
    },
    methods: {
        getStepSeconds() {
            return 5;
        },
        getWidthSegmentItemTimeline() {
            this.state.widthSegmentItemHeader = this.$refs.segment[0].getBoundingClientRect().width
            let fullWidthSepLineSegmentItem = this.state.widthSeparatorsLine * this.state.subsegmentsCount
            this.state.gapOffset = (((this.state.widthSegmentItemHeader - fullWidthSepLineSegmentItem) / this.state.subsegmentsCount) / this.state.unitRem)
        },
        getVideoSrc(): string {
            this.state.totalSeconds = this?.video?.duration.totalSeconds
            return this.video?.preparedFileData?.url || '';
        },
        toggleDropdownSave() {
            this.state.isDropdownVisible = !this.state.isDropdownVisible;
        },
        getTimelineHeaderSegmentsCount() {
            const countSegments = Math.floor((this.video?.duration?.totalSeconds || 1) / 5 + 1);
            return countSegments;
        },
        getTime(value) {
            return getTime(value)
        },

        // Resize
        mouseDownHandler(e) {
            this.state.mouse_x = e.pageX
            this.state.isActiveResize = true
            this.state.original_width = this.$refs.cutterFrame.clientWidth
            this.state.original_x = this.$refs.cutterFrame.getBoundingClientRect().left
            this.state.widthStickItem = this.$refs.rightStick.clientWidth

            if (e.target.closest('.left-stick')) {
                this.state.isLeftStick = true
                this.state.isRightStick = false
                this.state.isLeftStickActive = true
                if (this.left >= this.state.paddingsOfTimelineWrap) {
                    this.state.isTooltipLeftActive = true
                    this.state.isTooltipRightActive = false
                } else {
                    this.state.isTooltipLeftActive = false
                }
            }
            if (e.target.closest('.right-stick')) {
                this.state.isLeftStick = false
                this.state.isRightStick = true
                this.state.isRightStickActive = true
                this.state.isTooltipLeftActive = false

                if (this.state.isRight) {
                    this.state.isTooltipRightActive = true
                }
            }
            window.addEventListener('mousemove', this.resize)
            window.addEventListener('mouseup', this.stopResize)

            // console.log('width', this.width)
            // console.log('original_width', this.state.original_width)
            // console.log('widthTimelineRow', this.widthTimelineRow)
            //
            // console.log('----------------- Right -----------------')
            // console.log('width', this.width)
            // console.log('state.currentWidth', this.state.currentWidth)
            // console.log('left', this.left)
            // console.log('state.currentLeft', this.state.currentLeft)
            // console.log('scroll_X', this.state.scroll_X)
            // console.log('currentScroll_X', this.currentScroll_X)
            // console.log('state.isLeft', this.state.isLeft)
            // console.log('state.isRight', this.state.isRight)
        },
        resize(e) {
            if (this.state.isLeftStick) {
                if (!this.state.scroll_X && this.state.original_x + (e.pageX - this.state.mouse_x - this.state.widthStickItem) < 0) return
                this.state.isLeft = true
                this.width = this.state.original_width - (e.pageX - this.state.mouse_x)
                this.left = this.state.original_x + (e.pageX - this.state.mouse_x - this.state.widthStickItem)
                this.state.widthBackdropLeft = e.pageX - this.state.widthStickItem
                this.state.currentTimeStart = Math.floor(((this.left - this.state.paddingsOfTimelineWrap) + this.state.scroll_X) / 20)
                this.state.scroll_X = this.currentScroll_X
                if (this.left >= this.state.paddingsOfTimelineWrap) {
                    this.state.isTooltipLeftActive = true
                    this.state.isTooltipRightActive = false
                } else {
                    this.state.isTooltipLeftActive = false
                }
                this.$refs.cutterFrame.style.left = this.left + 'px'
                this.$refs.cutterFrame.style.width = this.width + 'px'

                // this.$refs.backdropLeft.style.width = this.state.widthBackdropLeft + 'px'

                // console.log('----------------- Left -----------------')
                // console.log('width', this.width)
                // console.log('state.currentWidth', this.state.currentWidth)
                // console.log('left', this.left)
                // console.log('state.currentLeft', this.state.currentLeft)
                // console.log('scroll_X', this.state.scroll_X)
                // console.log('currentScroll_X', this.currentScroll_X)
                // console.log('state.isLeft', this.state.isLeft)
                // console.log('state.isRight', this.state.isRight)
            }
            if (this.state.isRightStick) {
                if (!this.state.scroll_X && this.widthTimelineRow < (this.state.original_width + (e.pageX - this.state.mouse_x))) return
                this.state.isRight = true
                this.width = this.state.original_width + (e.pageX - this.state.mouse_x)
                this.$refs.cutterFrame.style.width = this.width + 'px'
                this.state.offsetLeftRightStick = this.$refs.rightStick.getBoundingClientRect().left
                const paddingsLeft = ((this.$refs.modalFooter.offsetWidth - this.$refs.timelineRow.clientWidth) / 2)
                const widthLeftStick = this.$refs.leftStick.clientWidth
                const allPaddings = this.state.paddingsOfTimelineWrap * 2 + widthLeftStick + paddingsLeft
                this.state.isTooltipRightActive = true
                this.state.currentTimeEnd = Math.ceil(((this.state.offsetLeftRightStick - allPaddings) + this.state.scroll_X) / 20)
                this.state.scroll_X = this.$refs.timeline.scrollLeft
                this.state.currentLeft = this.$refs.cutterFrame.offsetLeft
                this.state.widthBackdropRight = (this.widthTimelineRow - e.clientX) + this.state.widthStickItem
                // this.$refs.backdropRight.style.width = this.state.widthBackdropRight + 'px'

                // console.log('----------------- Right -----------------')
                // console.log('width', this.width)
                // console.log('state.currentWidth', this.state.currentWidth)
                // console.log('left', this.left)
                // console.log('state.currentLeft', this.state.currentLeft)
                // console.log('scroll_X', this.state.scroll_X)
                // console.log('currentScroll_X', this.currentScroll_X)
                // console.log('state.isLeft', this.state.isLeft)
                // console.log('state.isRight', this.state.isRight)
            }
        },
        handlerScroll() {
            this.$refs.timeline.scrollLeft = this.$refs.timelineHelper.scrollLeft
            this.currentScroll_X = this.$refs.timeline.scrollLeft

            if (this.state.isLeftStick && this.left) {
                this.state.currentWidth = this.width + (this.currentScroll_X - this.state.scroll_X)
                this.state.currentLeft = this.left - (this.currentScroll_X - this.state.scroll_X)

                this.$refs.cutterFrame.style.width = this.state.currentWidth + 'px'
                this.$refs.cutterFrame.style.left = this.state.currentLeft + 'px'

                /*if (this.state.currentLeft > 0) {
                    this.$refs.backdropLeft.style.width = this.state.currentLeft + this.state.widthStickItem + 'px'
                } else {
                    this.$refs.backdropLeft.style.width = 0 + 'px'
                }*/

                if (this.state.scroll_X > 0 && this.currentScroll_X > 0 && this.state.isLeft && this.state.isRight) {
                    this.$refs.cutterFrame.style.width = this.width + 'px'
                }
                if (this.state.scroll_X > 0 && !this.currentScroll_X && this.state.isLeft && this.state.isRight) {
                    this.$refs.cutterFrame.style.width = this.width + 'px'
                }
                if (!this.state.scroll_X && this.currentScroll_X > 0 && this.state.isLeft && this.state.isRight) {
                    this.$refs.cutterFrame.style.width = this.width + 'px'
                }

                // console.log('----------------- Scroll leftStick -----------------')
                // console.log('width', this.width)
                // console.log('state.currentWidth', this.state.currentWidth)
                // console.log('left', this.left)
                // console.log('state.currentLeft', this.state.currentLeft)
                // console.log('scroll_X', this.state.scroll_X)
                // console.log('currentScroll_X', this.currentScroll_X)
                // console.log('state.isLeft', this.state.isLeft)
                // console.log('state.isRight', this.state.isRight)
            }

            if (this.state.isRightStick) {
                if (this.state.scroll_X > 0 && this.currentScroll_X > 0 && this.state.isLeft && this.state.isRight) {
                    this.$refs.cutterFrame.style.left = this.state.currentLeft - (this.currentScroll_X - this.state.scroll_X) + 'px'
                }
                if (!this.state.scroll_X && this.currentScroll_X > 0) {
                    this.state.currentWidth = this.width
                    this.state.currentLeft = this.left - this.currentScroll_X

                    this.$refs.cutterFrame.style.width = this.state.currentWidth + 'px'
                    this.$refs.cutterFrame.style.left = this.state.currentLeft + 'px'
                }
                if (!this.state.scroll_X && this.currentScroll_X > 0 && !this.state.isLeft && !this.state.isRight) {
                    this.$refs.cutterFrame.style.width = this.state.original_width + 'px'
                }
                if (!this.state.scroll_X && this.currentScroll_X > 0 && !this.state.isLeft && this.state.isRight) {
                    this.$refs.cutterFrame.style.width = 0 + 'px'
                }
                if (!this.state.scroll_X && !this.state.isLeft) {
                    this.$refs.cutterFrame.style.width = this.state.currentWidth - this.currentScroll_X + 'px'
                    this.$refs.cutterFrame.style.left = this.left + 'px'
                }
                if (this.state.scroll_X > 0 && this.currentScroll_X > 0 && !this.state.isLeft && this.state.isRight) {
                    this.state.currentWidth = this.width
                    this.state.currentLeft = this.left - this.currentScroll_X

                    this.$refs.cutterFrame.style.width = this.state.currentWidth - (this.currentScroll_X - this.state.scroll_X) + 'px'
                    this.$refs.cutterFrame.style.left = this.left + 'px'
                }
                if (!this.state.scroll_X && !this.currentScroll_X && this.state.isLeft && this.state.isRight) {
                    this.$refs.cutterFrame.style.left = this.left + 'px'
                }
                if (this.state.scroll_X && !this.currentScroll_X && this.state.isLeft && this.state.isRight) {
                    this.$refs.cutterFrame.style.left = this.left + 'px'
                }

                // this.$refs.backdropRight.style.width = this.state.widthBackdropRight + (this.currentScroll_X - this.state.scroll_X) + 'px'

                /*if (this.state.currentLeft > 0) {

                } else {
                    this.$refs.backdropRight.style.width = 0 + 'px'
                }*/

                // console.log('----------------- Scroll rightStick -----------------')
                // console.log('width', this.width)
                // console.log('state.currentWidth', this.state.currentWidth)
                // console.log('left', this.left)
                // console.log('state.currentLeft', this.state.currentLeft)
                // console.log('scroll_X', this.state.scroll_X)
                // console.log('currentScroll_X', this.currentScroll_X)
            }
            // console.log('----------------- Scroll -----------------')
            // console.log('width', this.width)
            // console.log('state.currentWidth', this.state.currentWidth)
            // console.log('left', this.left)
            // console.log('state.currentLeft', this.state.currentLeft)
            // console.log('scroll_X', this.state.scroll_X)
            // console.log('currentScroll_X', this.currentScroll_X)
            // console.log('state.isLeft', this.state.isLeft)
            // console.log('state.isRight', this.state.isRight)
        },
        stopResize() {
            window.removeEventListener('mousemove', this.resize)
        },
        showTooltipTimeStart() {
            this.state.isLeftStickActive = true
            this.state.isRightStickActive = true
            this.state.isActiveResize = true
        },
        showTooltipTimeEnd() {
            this.state.isLeftStickActive = true
            this.state.isRightStickActive = true
            this.state.isActiveResize = true
        },
        handlerTrim() {
            this.state.isTrim = true
            this.state.isCutOut = false
            /*this.$refs.backdropLeft.style.width = this.state.widthBackdropLeft - (this.currentScroll_X - this.state.scroll_X) + 'px'
            this.$refs.backdropRight.style.width = this.state.widthBackdropRight + 'px'*/
        },
        handlerCutOut() {
            this.state.isTrim = false
            this.state.isCutOut = true
        },
        closeModal() {
            this.$emit('toggleVideoTrimModalVisibility')
            this.state.videoEl.currentTime = 0
            this.currentScroll_X = 0
            this.videoPlayerCurrentTime = 0
            this.state = Object.assign({}, this.defaultState)
            this.forcedRender = !this.forcedRender
        },
        save() {
            this.$refs.saveVideoTrim.show()
            this.state.newVideo = false
        },
        saveAsNew() {
            this.$refs.saveVideoTrim.show()
            this.state.newVideo = true
        },
        updateVideoProgressBar() {
            if (this.showModal) {
                this.$refs.timelineHelper.scrollTo(this.videoPlayerCurrentTime * 20, 0)
            }
        },
        onVideoPlayerReady(event) {
            this.state.videoEl = event.video;
        },
        handlerScrollUp() {
            this.state.isScrollTouch = false
            this.state.isVideoActive = false
        },
        handlerScrollDown() {
            this.state.isScrollTouch = true
        },
        videoActive() {
            this.state.isVideoActive = true
            this.state.isScrollTouch = false
        }
    },
});
