import {i18n} from '@app/i18n';
import {api} from '@app/system/api/api-services';
import {
    IParticipantDto, IVideoModeratorDto,
    ParticipantState,
    SpecificationParameters,
} from '@app/system/api/service-proxies/service-proxies';

function initialState() {
    return {
        isLoading: false,
        isServerError: false,
        serverErrorMessage: null,

        /* Filter - recognized persons */
        recognizedPersons: [],
        recognizedPersonsLimit: 1000,
        list: 1,

        filters: [],
        videoModerators: null
    };
}

const getters = {
    isLoading(state) {
        return state.isLoading;
    },
    persons(state) {
        return state.persons;
    },
    recognizedPersons(state) {
        // console.log('state.recognizedPersons', state.recognizedPersons);
        return state.recognizedPersons;
    },
    personsCount(state) {
        return state.personsCount;
    },
    currentPage(state) {
        return state.currentPage;
    },
    filters(state) {
        return state.filters;
    },
    videoModerators(state) {
        // console.log('state.videoModerators', state.videoModerators);
        return state.videoModerators
    }
};

const actions = {
    getRecognizedPersons({commit, state}) {
        const pageNumber = state.list;
        const pageSize = state.recognizedPersonsLimit;
        const statusParam = ParticipantState._20;
        const filterByState = new SpecificationParameters({
            specId: 'F8513C72-62F2-42CC-BAF0-3E38C941F710',
            parameters: {value: statusParam},
        });

        /*const associationPersons = new SpecificationParameters({
            specId: 'B7E4B2B9-7BEF-4BD2-9DC4-8FAA911A9AF9',
            parameters: {value: true},
        });*/

        const specifications = [
            filterByState,
            // associationPersons,
        ];

        return api.participantsServiceProxy.page(pageNumber, pageSize, true, '1.0', specifications)
            .toPromise()
            .then(resp => {
                    const recognizedPersons: IParticipantDto[] = resp.data
                        .filter(person => person.isPrimary)
                        .sort((a, b) => a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : -1);

                    commit('setRecognizedPersons', recognizedPersons);
                },
            )
            .catch((err) => {
                commit('setServerError', err);
            });
    },

    getVideoModerators({commit, state}) {
        const pageNumber = state.list;
        const pageSize = state.recognizedPersonsLimit;
        const statusParam = ParticipantState._20;
        const filterByState = new SpecificationParameters({
            specId: '13BE9577-6C14-49ED-87D9-8E17E141925F',
            parameters: {value: statusParam},
        });

        const specifications = [filterByState];

        return api.videoModeratorServiceProxy.page(pageNumber, pageSize, true, '1.0', specifications)
            .toPromise()
            .then(resp => {
                // console.log('resp getVideoModerators ============>', resp);
                    const moderators: IVideoModeratorDto[] = resp.data
                        .sort((a, b) => a.person.fullName.toLowerCase() > b.person.fullName.toLowerCase() ? 1 : -1);

                    commit('setVideoModerators', moderators);
                },
            )
            .catch((err) => {
                commit('setServerError', err);
            });
    },
};

/* eslint-disable no-param-reassign */
const mutations = {
    setServerError(state, err) {
        state.isServerError = true;
        if (err.response && err.response.data && err.response.data.error) {
            state.serverErrorMessage = err.response.data.error;
        } else {
            state.serverErrorMessage = i18n.global.t('unknown_error');
        }
    },

    setRecognizedPersons(state, persons) {
        state.recognizedPersons = persons;
    },

    setFilter(state, filters) {
        filters.forEach(filter => {
            if (filter.secondColumnVal === 'staff') {
                const filteredPerson = state.recognizedPersons.find(person => person.id === filter.fourthColumnVal)
                filter.personFullName = filteredPerson ? filteredPerson?.fullName : false
            }
            if (filter.secondColumnVal === 'moderator') {
                const filteredModerator = state.videoModerators.find(moderator => moderator.id === filter.fourthColumnVal)
                filter.personFullName = filteredModerator?.fullName
            }
        })
        state.filters = filters;
    },
    setVideoModerators(state, moderators) {
        const uniquePersons = {};

        moderators.forEach(item => {
            const person = item.person;
            const fullName = person.id;
            if (!uniquePersons[fullName]) {
                uniquePersons[fullName] = person;
            }
        });
        state.videoModerators  = Object.values(uniquePersons)
    },
    setCurrentFilterValue(state, participant) {
        state.filters.forEach(item => {
            item.personFullName = participant
        })
    }
};
/* eslint-enable no-param-reassign */

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
