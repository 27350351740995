

import moment from 'moment';

import {useVuelidate} from '@vuelidate/core';
import {helpers, maxLength, minLength, required} from '@vuelidate/validators';
import {defineComponent} from 'vue';
import DatePicker from 'vue-datepicker-next';
import {mapActions, mapGetters} from 'vuex';

import Modal from '@app/components/ui-kit/molecules/Modal.vue';
import {i18n} from '@app/i18n';
import {uploadVideoFile} from '@app/modules/video/video.api';
import {api} from '@app/system/api/api-services';
import {IManualUploadedVideoDto, ManualUploadedVideoDto} from '@app/system/api/service-proxies/service-proxies';
// import Loader from '@app/components/ui-kit/molecules/Loader.vue';

const validateRoleName = helpers.regex(/^[а-яА-ЯёЁ\w\-!?@#$() +":']+$/);

export default defineComponent({
    name: 'VideoUploadModal',
    components: {
        DatePicker,
        Modal,
        // Loader,
    },
    props: {
        /*role: {
            type: Object,
            required: false
        },*/
    },
    emits: [
        'submitted',
    ],
    setup() {
        const initForm = {
            file: null,
            title: '',
            startTime: new Date(),
            moderators: [],
        };

        return {
            v$: useVuelidate(),
            initForm,
        };
    },
    data() {
        return {
            id: 'video-upload-modal',
            modalShow: false,
            valid: false,
            form: Object.assign({}, this.initForm),
            submitting: false,
            isFirstLoading: true,
            isLoading: false,
            disable: false,
        };
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),

        ...mapGetters('persons', {
            allPersons: 'allPersons',
        }),

        isSubmitAvailable() {
            const {file, title, startTime, moderators} = this.form;

            /*if (!this.isGrantedPolicy('Recorder.Video.Upload')) {
                return false;
            }*/

            console.log(file, title, startTime, moderators);

            return file && title?.length >= 2 && startTime;
        },
    },

    validations() {
        return {
            form: {
                title: {required, minLength: minLength(2), maxLength: maxLength(128), validateRoleName},
            },
        };
    },

    watch: {},

    async mounted() {
        this.locale = i18n.global.locale;
        await this.getPersonsAllList();
    },

    methods: {
        ...mapActions('persons', {
            getPersonsAllList: 'getAllList',
        }),

        show() {
            this.modalShow = true;
        },

        hide() {
            this.modalShow = false;
        },

        async resetModal() {
            await this.resetForm();
        },

        async closeModal() {
            this.hide();
        },

        async submitForm() {
            const {file, title, startTime, moderators} = this.form;

            this.submitting = true;
            this.isLoading = true;

            try {
                const fileDataDtoArr = await uploadVideoFile(file);
                const fileDataDto = fileDataDtoArr[0];

                const uploadVideoDto: Omit<IManualUploadedVideoDto, 'id'> = {
                    title,
                    startTime: moment(startTime),
                    isConfidential: false,
                    videoFileDataId: fileDataDto.id,
                    description: '',
                    moderators: moderators || [],
                };
                await api.manualUploadServiceProxy.save('1.0', uploadVideoDto as ManualUploadedVideoDto).toPromise();

                this.isLoading = false;
                this.submitting = false;

                this.hide();
                this.$emit('submitted');
                this.form = Object.assign({}, this.initForm);
            } catch (e) {
                console.log(e);
            }
        },

        async resetForm() {
            this.form.isLoading = true;

            this.form.permissions = {...this.permissionsDefault};
            this.form.title = '';

            this.form.isLoading = false;
        },

        changeFileInput(e) {
            const files = e.target.files || e.dataTransfer.files;
            const file: File | undefined = files?.[0];

            if (!file) {
                return;
            }

            this.form.file = file;
            this.form.title = file.name;
            this.form.startTime = new Date();
            // this.$refs.datepicker.selectDate(this.form.startTime);
            console.log(this.$refs.datepicker);
        },

        handleClickSelectFileBtn() {
            this.$refs.inputFile.click();
        },
    },
});
