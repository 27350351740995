
import {defineComponent} from 'vue';
import {mapGetters, mapMutations} from 'vuex';

import DropdownMenuInVideos from '@app/components/DropdownMenuInVideos.vue';
import MessagePlate from '@app/components/MessagePlate.vue';
import {config} from '@app/config';
import {changeVideoPublicity} from '@app/modules/video/video.api';
import {changeVideoMark} from '@app/system/api/old/video';
import {IVideoDto} from '@app/system/api/service-proxies/service-proxies';

export default defineComponent({
    name: 'VideoList',
    components: {
        MessagePlate,
        DropdownMenuInVideos,
    },
    props: {
        fullTextSearchMode: {
            type: Boolean,
            default: false,
        },
        isShowMessage: {
            type: Boolean,
            default: false,
        },
        isToggleListView: {
            type: Boolean,
            default: false,
        },
        fullTextSearchStr: {
            type: String,
            default: '',
        },
        fullTextSearchPersonResults: {
            type: Array,
            required: true,
        },
        selectedSearchResultCallback: {
            type: Function,
            required: true,
        },
        paginatedSearchVideos: {
            type: Array,
            required: true,
        },
        fullTextSearchVideosResult: {
            type: Array,
            required: true,
        },
        editDescription: {
            type: Function,
            required: true,
        },
        changeUserAccessOfVideo: {
            type: Function,
            required: true,
        },
        videosCountSearch: {
            type: Number,
            default: 0,
        },
        serverErrorMessage: {
            type: Boolean,
            default: null,
        },
        videos: {
            type: Array,
            required: true,
        },
        videosListScrollLeft: {
            type: Number,
            default: 0,
        },
    },
    emits: [
        'getVideos',
        'fullTextSearch',
        'removeVideo',
        'getWidthTable',
        'removeSelectedVideos',
        'parameterSorting',
        'scrollingSecondaryScrollbar'
    ],

    data() {
        return {
            recognitionMode: config.recognitionMode,
            photoPath: config.photoPath,
            ctrlKeyPress: false,
            dropdownPosition: {
                top: null,
                left: null,
            },
            parameterLibrary: [
                {id: 1, name: 'Дата', value: 'date'},
                {id: 2, name: 'Длительность', value: 'duration'},
                {id: 3, name: 'Размер', value: 'fileSize'},
                {id: 4, name: 'Участники', value: 'participants'},
            ],
            currentVideo: null,
            videoListWidth: null,

            isSelectedVideosAll: false,

            selectedVideos: [],
            selectedVideosAll: null,
            screenWidth: 0,
        };
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),
        ...mapGetters('video', {
            videos: 'videos',
        }),
    },
    watch: {
        videosListScrollLeft() {
            this.$refs.videosList.scrollLeft = this.videosListScrollLeft;
        },
        isToggleListView() {
            if (this.isToggleListView) {
                this.selectedVideosAll = null;
                this.selectedVideos = [];
                this.isSelectedVideosAll = false;
            }
        },
    },

    mounted() {
        if (this.$refs.videosList) {
            this.videoListWidth = this.$refs.videosList.offsetWidth;
        }
        this.$emit('getWidthTable', this.$refs.table?.offsetWidth);
    },

    methods: {
        ...mapMutations('video', {
            setVideosSelected: 'setVideosSelected',
        }),

        getVideoSize(bytes) {
            return (bytes / (1024 * 1024)).toFixed(2);
        },

        getVideoInstanceFullUrl(id) {
            const path = this.$router.resolve({name: 'videos-instance', params: {id: id}}).href;
            return window.location.origin + path;
        },

        highlighter(value: string | undefined, substrings): string {
            if (!value) {
                return '';
            }

            let result: string = value;
            substrings.split(' ')?.forEach((item) => {
                if (item.length < 1) {
                    return;
                }
                const re = new RegExp(item, 'gi');
                result = result.replace(re, `<span class="text-search-highlight">${'$&'}</span>`);
            });

            return result;
        },

        async changeVideoMarkToDelete(value, video_id) {
            await changeVideoMark(video_id, 'mark_to_delete', value);
        },

        async changeVideoMarkToSave(value, video_id) {
            await changeVideoMark(video_id, 'mark_to_save', value);
        },

        async changePublicityCallback(videoId, publicity) {
            await changeVideoPublicity(videoId, publicity);
            if (this.fullTextSearchMode) {
                this.$emit('fullTextSearch');
            } else {
                this.$emit('getVideos');
            }
        },

        isModerator(video) {
            return video?.read_only === false;
        },

        openVideo(id, event) {
            const videoData = JSON.parse(localStorage.getItem('videos_data')) || [];
            const selectedVideo = {
                video_id: id,
                recentViewingTimes: 0,
            };
            const res = videoData.filter(item => item.video_id === id);
            if (!res.length) {
                videoData.push(selectedVideo);
            }
            localStorage.setItem('videos_data', JSON.stringify(videoData));

            const isMiddleClick = event.button === 1;
            const isRightClick = event.button === 2;

            if (event.ctrlKey || isMiddleClick) {
                window.open(this.$router.resolve({name: 'videos-instance', params: {id: id}}).href, '_blank');
            } else if (isRightClick) {
                console.log('event', event)
            } else {
                this.$router.push({name: 'videos-instance', params: {id: id}});
            }
        },

        parameterSorting(name: string) {
            this.$emit('parameterSorting', name);
        },

        handleSelectedVideosAll() {
            const areAllChecked = this.$refs.checkbox.every((checkbox: HTMLInputElement) => checkbox.classList.contains(
                'active'));
            this.$refs.checkboxChangeAll.classList.toggle('active', !areAllChecked);

            this.$refs.checkbox.forEach((checkbox: HTMLInputElement) => {
                checkbox.classList.toggle('active', !areAllChecked);
            });

            this.isSelectedVideosAll = !this.isSelectedVideosAll;
            this.selectedVideosAll = areAllChecked ? 0 : this.$refs.checkbox.length;

            if (areAllChecked) {
                this.selectedVideos = [];
            } else {
                this.selectedVideos = !this.fullTextSearchMode
                    ? [...this.videos]
                    : [...this.fullTextSearchVideosResult];
            }
        },

        handleSelectedVideo(index: Number, video: IVideoDto) {
            this.$refs.checkbox.forEach((checkbox: HTMLInputElement, idx: Number) => {
                if (index === idx) {
                    checkbox.classList.toggle('active');
                    if (checkbox.classList.contains('active')) {
                        this.selectedVideos.push(video);
                    } else {
                        let indexEl = this.selectedVideos.findIndex(item => item.id === video.id);
                        this.selectedVideos.splice(indexEl, 1);
                    }
                }
            });
        },

        toggleDropdown(index: Number, video: IVideoDto) {
            console.log('this.$refs.dropdownMenu', this.$refs.dropdownMenu)
            if (this.$refs.dropdownMenu) {
                this.$refs.dropdownMenu.showDropdown();
            }

            this.currentVideo = video;
            this.$refs.iconMore.forEach((icon: HTMLInputElement, idx: Number) => {
                if (index === idx) {
                    this.dropdownPosition.top = icon.getBoundingClientRect().top;
                    this.dropdownPosition.left = icon.getBoundingClientRect().left;
                }
            });
        },

        async removeVideo(video: IVideoDto) {
            this.$emit('removeVideo', video);
            this.selectedVideos = [];
            this.$refs.checkbox.forEach((checkbox: HTMLInputElement) => {
                if (checkbox.classList.contains('active')) {
                    checkbox.classList.remove('active');
                }
            });
        },

        async removeSelectedVideos() {
            this.$emit('removeSelectedVideos', this.selectedVideos);
        },

        handlerScrollVideosListByX() {
            this.$emit('scrollingSecondaryScrollbar', this.$refs.videosList.scrollLeft)
        }
    },
});

