
import {defineComponent} from 'vue';
import {mapActions, mapMutations} from "vuex";

import UiErrorSnackbar from "@app/components/ui-kit/molecules/ErrorSnackbar.vue";
import {setShowToastErrorHandler} from "@app/system/api/xhr-factory";
import UserActivityExpiredModal from "@app/views/blocks/UserActivityExpiredModal.vue";

export default defineComponent({
    name: 'App',
    components: {
        UiErrorSnackbar,
        UserActivityExpiredModal,
    },
    data: () => ({
        loadDataAttempts: 0,
    }),

    mounted() {
        setShowToastErrorHandler((errorText: string) => {
            this.$refs.errorSnackbar.show({message: errorText});
        });

        this.loadData();
    },

    methods: {
        ...mapActions('video', {
            isParticipantFullName: 'isParticipantFullName'
        }),
        ...mapMutations('video', {
            setViewScreenVideos: 'setViewScreenVideos',
        }),

        loadData() {
            this.loadDataAttempts++;

            setTimeout(() => {
                const $oidc = window['$oidc'];
                const accessToken = $oidc.accessToken || null;
                if (accessToken) {
                    this.isParticipantFullName();
                } else if (this.loadDataAttempts < 5) {
                    this.loadData();
                }
            }, 500);
        },
    },
});
