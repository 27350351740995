

import moment from 'moment';

import {defineComponent} from 'vue';
import DatePicker from 'vue-datepicker-next';
import {mapActions, mapGetters} from 'vuex';

import Modal from '@app/components/ui-kit/molecules/Modal.vue';
import SwitchBox from "@app/components/ui-kit/molecules/SwitchBox.vue";
import {IVideoGrantPersistentAccessInput} from "@app/system/api/service-proxies/service-proxies";

export default defineComponent({
    name: 'CreateAccessModal',
    components: {
        SwitchBox,
        DatePicker,
        Modal,
    },
    emits: ['callback'],
    data() {
        return {
            id: 'create-access-modal',
            form: {
                name: '',
                term: null,
                indefinitely: true,
                selectedUsersForAccess: [],
                viewingPermission: true,
                editingPermission: false,
            },
            modalShow: false,
            errorText: '',
            createAccessUrl: true,
            accessByEmail: false,
            paginationInfo: {
                perPage: 20,
                currentPage: 1,
            },
        }
    },
    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
        }),
        ...mapGetters('users', {
            users: 'users',
        }),
        ...mapGetters('video', {
            video: 'video',
        }),
        isSubmitAvailable() {
            if (!this.accessByEmail) {
                return !this.form.name;
            } else {
                return (
                    (!this.form.selectedUsersForAccess.length && (!this.form.viewingPermission && !this.form.editingPermission))
                    || (!this.form.selectedUsersForAccess.length && (this.form.viewingPermission || this.form.editingPermission))
                    || (this.form.selectedUsersForAccess.length && (!this.form.viewingPermission && !this.form.editingPermission))
                );
            }
        },
    },
    watch: {
        'indefinitely.form'() {
            if (this.form.indefinitely) {
                this.form.term = null
            }
        },
    },

    async mounted() {
        if (this.isGrantedPolicy('Recorder.UserAccount')) {
            await this.getList();
        }
    },
    methods: {
        ...mapActions('video', {
            createAccess: 'createAccess',
            revokeAccess: 'revokeAccess',
            grantPersistentAccess: 'grantPersistentAccess',
            getAccessesPersons: 'getAccessesPersons',
        }),
        ...mapActions('users', {
            getList: 'getList',
        }),

        show() {
            this.modalShow = true;
            this.form.name = '';
            this.form.term = null;
            this.form.indefinitely = true;
            this.form.selectedUsersForAccess = [];
            this.accessByEmail = false;
        },

        hide() {
            this.modalShow = false;
        },

        async closeModal() {
            this.hide();
        },

        async submitForm() {
            this.closeModal();
            if (this.createAccessUrl && !this.accessByEmail) {
                this.form.targetUrl = this.$route.path
                this.form.term = moment(this.form.term, 'YYYY-MM-DD')
                    .add(1, 'day')
                    .format('YYYY-MM-DD');

                this.createAccess(this.form)
                    .then(() => {
                        this.$emit('callback', this.createAccessUrl);
                        this.closeModal()
                    })
            } else {
                if (this.form.selectedUsersForAccess.length) {
                    for (const item of this.form.selectedUsersForAccess) {
                        const payload: IVideoGrantPersistentAccessInput = {
                            videoId: this.video.id,
                            userId: item.id,
                            expiresAfter: null,
                            description: this.form.editingPermission ? 'viewing and editing' : 'viewing',
                            role: this.form.editingPermission ? 1 : 0
                        }
                        await this.revokeAccess(payload);
                        await this.grantPersistentAccess(payload);
                        await this.getAccessesPersons(this.paginationInfo)
                    }
                }
            }
        },

        disabledPast(date: Date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
    },
});
