

import {defineComponent} from 'vue';
import {copyText} from 'vue3-clipboard';
import {mapGetters, mapState} from 'vuex';

import {getWidthScreen} from '@app/utils/helpers';

export default defineComponent({
    name: 'DropdownMenu',
    props: {
        objectData: {
            type: Object,
            required: true,
        },
        permissions: {
            type: Object,
            required: true,
        },
        dataType: {
            type: String,
            required: false,
            default: null,
        },
        accessTicket: {
            type: Object,
            required: false,
            default: null,
        },
    },
    emits: ['edit', 'remove', 'showMessageSuccessCopyVideoUrl'],

    data() {
        return {
            dropdownVisible: false,
        };
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            isAdmin: 'isAdmin',
            userEmail: 'userEmail',
        }),
        ...mapState('video', [
            'video',
        ]),

        isMobile() {
            return getWidthScreen();
        },
    },

    methods: {
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },

        edit() {
            this.toggleDropdown();
            this.$emit('edit', this.objectData);
        },

        remove() {
            this.toggleDropdown();
            this.$emit('remove', this.objectData);
        },

        clipboardCopyText() {
            if (!this.accessTicket) {
                return;
            }

            const accessTicketId = this.accessTicket.id;
            // const videoId = this.accessTicket.targetUrl.split('/')[2];

            const path = this.$router.resolve({name: 'share', params: {accessTicketId}}).href;
            const fullUrl = window.location.origin + path;

            copyText(fullUrl, undefined, (error, event) => {
                console.log(error);
                if (event.action) {
                    this.toggleDropdown();
                    this.$emit('showMessageSuccessCopyVideoUrl');
                }
            });
        },
    },
});
