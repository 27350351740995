
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import {config} from '@app/config';
import LanguageChanger from '@app/views/blocks/LanguageChanger.vue';
import Sidebar from '@app/views/blocks/Sidebar.vue';

export default defineComponent({
    name: 'PanelLayout',
    components: {
        Sidebar,
        LanguageChanger,
    },
    data() {
        return {
            config,
            sidebarOpened: false,
        }
    },

    computed: {
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            isAdmin: 'isAdmin',
        }),
    },

    methods: {
        async sidebarToggleCallback(opened) {
            this.sidebarOpened = opened;
        },

        closeMobileMenu() {
            document.body.className = document.body.className.replace('mobile-menu--open', '');
        }

    },
});
