import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25c64578"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "person-modal" }
const _hoisted_2 = { class: "form__fields" }
const _hoisted_3 = { style: {"color":"red"} }
const _hoisted_4 = { class: "form__switch-box" }
const _hoisted_5 = { class: "param-1" }
const _hoisted_6 = { class: "param-2" }
const _hoisted_7 = { class: "form__block-checkboxes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchBox = _resolveComponent("SwitchBox")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_vue_multiselect = _resolveComponent("vue-multiselect")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    id: _ctx.id,
    ref: "modal",
    "modal-show": _ctx.modalShow,
    title: _ctx.$t('modal_titles.access_video'),
    persistent: true,
    size: "lg",
    "ok-title": _ctx.$t('buttons.provide'),
    "cancel-title": _ctx.$t('buttons.cancel'),
    "ok-disabled": _ctx.isSubmitAvailable,
    onHidden: _ctx.closeModal,
    onOk: _ctx.submitForm
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_b_form, {
                key: 0,
                class: "form",
                onSubmit: _ctx.submitForm
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorText), 1),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_SwitchBox, {
                      modelValue: _ctx.accessByEmail,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accessByEmail) = $event))
                    }, {
                      items: _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('navs.by_link')), 1),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('navs.by_email')), 1)
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  (!_ctx.accessByEmail)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_b_form_group, { id: "input-group-name" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_form_input, {
                              id: "input-name",
                              ref: "inputName",
                              modelValue: _ctx.form.name,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event)),
                              modelModifiers: { trim: true },
                              placeholder: _ctx.$t('placeholders_common.name'),
                              type: "text"
                            }, null, 8, ["modelValue", "placeholder"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_date_picker, {
                          value: _ctx.form.term,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.term) = $event)),
                          format: "YYYY-MM-DD",
                          "value-type": "format",
                          type: "date",
                          range: false,
                          "first-day-of-week": 1,
                          lang: _ctx.$i18n.locale,
                          disabled: _ctx.form.indefinitely,
                          "disabled-date": _ctx.disabledPast,
                          placeholder: _ctx.$t('placeholders_common.validity_period'),
                          class: "form__access-datepicker"
                        }, null, 8, ["value", "lang", "disabled", "disabled-date", "placeholder"]),
                        _createVNode(_component_b_form_checkbox, {
                          modelValue: _ctx.form.indefinitely,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.indefinitely) = $event)),
                          style: {paddingLeft: '0'},
                          class: "form__checkbox"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('checkbox_labels.indefinitely')), 1)
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(_component_b_form_group, {
                          id: "input-identity-role",
                          "label-for": "input-identity-role"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_vue_multiselect, {
                              modelValue: _ctx.form.selectedUsersForAccess,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.selectedUsersForAccess) = $event)),
                              placeholder: _ctx.$t('placeholders_common.access_name_or_email'),
                              label: "fullName",
                              "track-by": "id",
                              options: _ctx.users,
                              multiple: true,
                              taggable: true,
                              searchable: true
                            }, null, 8, ["modelValue", "placeholder", "options"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_b_form_checkbox, {
                            modelValue: _ctx.form.viewingPermission,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.viewingPermission) = $event)),
                            class: "form__checkbox"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('permissions.RecorderVideo')), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_b_form_checkbox, {
                            modelValue: _ctx.form.editingPermission,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.editingPermission) = $event)),
                            class: "form__checkbox"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('permissions.RecorderVideoEditing')), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ])
                      ], 64))
                ]),
                _: 1
              }, 8, ["onSubmit"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["id", "modal-show", "title", "ok-title", "cancel-title", "ok-disabled", "onHidden", "onOk"]))
}