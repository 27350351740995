
import 'splitpanes/dist/splitpanes.css';
import cloneDeep from 'lodash-es/cloneDeep';

import {defineComponent} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

import {isGrantedPolicy} from '@app/modules/auth/auth.store';
import EditPersonModal from '@app/modules/person/blocks/EditPersonModal.vue';
import ProtocolBlock from '@app/modules/video/blocks/ProtocolBlock.vue';
import TimelineBlock from '@app/modules/video/blocks/TimelineBlock.vue';
import VideoBlock from '@app/modules/video/blocks/VideoBlock.vue';
import VideoTrimModal from '@app/modules/video/blocks/VideoTrimModal.vue';
import {getTime} from '@app/utils/helpers';

import EditDescriptionModal from '../blocks/EditDescriptionModal.vue';
import ModalDelete from '@app/components/ui-kit/molecules/ModalDelete.vue';
import {updateVideo} from '@app/modules/video/video.api';

// TODO: временно не используются
// import CopyVideoLinkBtn from "@app/modules/video/blocks/CopyVideoLinkBtn.vue";
// import Note from "@app/modules/video/blocks/Note.vue";
// import VideoRowUsersWithAccessMobile from "@app/modules/video/blocks/VideoRowUsersWithAccessMobile.vue";
// import VideoUsersInVideoInstanceMobile from "@app/modules/video/blocks/VideoUsersInVideoInstanceMobile.vue";
// import VideoInstanceMenuMobile from "@app/modules/video/blocks/VideoInstanceMenuMobile.vue";

export default defineComponent({
    name: 'VideoInstancePage',
    components: {
        ModalDelete,
        TimelineBlock,
        ProtocolBlock,
        VideoBlock,
        VideoTrimModal,
        EditPersonModal,
        EditDescriptionModal,

        // TODO: временно не используются
        // VideoRowUsersWithAccessMobile,
        // CopyVideoLinkBtn,
        // VideoUsersInVideoInstanceMobile,
        // VideoInstanceMenuMobile,
        // Note,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        accessTicketId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            selected_person: null,
            isLoading: true,

            // For inject to Player Component (for timeline)
            videoPlayerCurrentTime: 0,
            videoPlayerDuration: 0,

            // Split panes
            splitPanesHeightPx: 600,

            // Timeline
            timelineHeaderHeightPx: 64,
            timelineHeaderMarginPx: 34,
            timelinePersonHeightPx: 50,
            timelinePersonMinCount: 2,
            timelinePersonCount: 2,

            // Fix see in this.resizedSplitPanesCallback
            timelinePersonCountFix: false,
            videoHeight: 100,
            playerWidth: 1850,
            selectedTabIndex: 0,
            videoProgressBarWidth: '0',
            videoCurrentTimeStr: '',
            usersAccess: {},
            allUser: [],
            screenWidth: '',
            videoEl: null,
            videoTrimModalVisible: false,
            skeletonProtocolHeightOne: null,
            skeletonProtocolHeightTwo: null,
            skeletonPlayer: null,
            protocolHeight: null,
            windowWidth: null,
            showModal: false,
            showMessage: false,
            forcedShowModal: false,
            videoInstanceBg: null,
            selectedTimeOfProtocol: null,
            selectedTimeOfTimeline: null,
            heightTimeline: null,
            widthProtocolBlock: null,
            isMobile: false,
            tabSelected: 0,
            videoSectionHeight: null,
            // fullPublicVideoLink: null
        };
    },
    computed: {
        ...mapState('video', [
            'video',
            'player',
            'error',
        ]),
        ...mapGetters('auth', {
            isGrantedPolicy: 'isGrantedPolicy',
            jwt: 'jwt',
        }),
    },
    watch: {
        video() {
            if (this.video?.id && !this.video?.id) {
                this.id = this.video.id;
                this.getVideo({id: this.id});
            } else {
                this.isLoading = false;
                if (this.jwt()) {
                    this.reInitUsersAccess();
                }
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        setTimeout(this.handleResize.bind(this), 0);
    },
    updated() {
        this.isMobile = this.windowWidth <= 990;
        this.windowWidth = window.innerWidth;
        if (!this.isLoading) {
            this.protocolHeight = this.videoInstanceBg + this.heightTimeline;
        }
    },
    async mounted() {
        await this.getVideo({id: this.id, accessTicketId: this.accessTicketId});

        this.screenWidth = document.documentElement.clientWidth;
        this.skeletonProtocolHeightOne = this.skeletonPlayer + this.heightTimeline;

        this.setFullPath(this.$route.path);
    },
    methods: {
        ...mapActions('video', [
            'getVideo',
            'addVideoAccessForUser',
            'deleteVideoAccessForUser',
            'changeVideoPublicity',
        ]),

        ...mapActions('users', {
            getAllUsers: 'getAllList',
        }),

        ...mapActions('video', {
            deleteVideo: 'deleteVideo',
        }),

        ...mapMutations('route', {
            setFullPath: 'setFullPath',
        }),

        getVideoSectionHeight(videoSectionHeight) {
            this.videoSectionHeight = videoSectionHeight;
        },

        handleProtocolTimeClick(time: number) {
            this.selectedTimeOfProtocol = time;
        },
        videoCurrentTime(videoCurrentTime) {
            this.videoPlayerCurrentTime = videoCurrentTime;
        },
        getVideoInstanceBg(data) {
            this.videoInstanceBg = data;
        },
        goToVideoListAfterVideoRemove() {
            this.$router.push({name: 'videos'});
        },
        removeVideo() {
            this.$refs.modalDelete.show();
        },
        editDescription() {
            if (!isGrantedPolicy('Recorder.Video', false, this.video.permissions)) {
                return;
            }
            this.$refs.editDescriptionModal.show();
        },
        loadVideo() {
            this.getVideo({id: this.id, accessTicketId: this.accessTicketId});
        },
        async onDeleteVideo() {
            await updateVideo(this.video, this.formData)
                .then(() => {
                    this.deleteVideo(this.video.id)
                        .then(() => {
                            this.$refs.modalDelete.hide();
                            this.$refs.editDescriptionModal.hide();
                            this.goToVideoListAfterVideoRemove();
                        });
                });
        },
        editPersonMobileCallback(person) {
            this.selected_person = String(person.id);
        },
        editPersonCallback(person) {
            if (!isGrantedPolicy('Recorder.Video', false, this.video.permissions)) {
                return;
            }

            this.selected_person = String(person.id);
            setTimeout(() => {
                this.$refs.editPersonModal.show();
            }, 100);
        },
        handleEditPersonCallback() {
            this.getVideo({id: this.id, accessTicketId: this.accessTicketId});
        },
        selectTab($event, tabIndex) {
            if ($event) {
                $event.preventDefault();
            }
            this.selectedTabIndex = tabIndex;
        },
        tabIsSelected(tabIndex) {
            this.tabSelected = this.selectedTabIndex;
            return tabIndex === this.selectedTabIndex;
        },
        handleResize() {
            this.selectTab(null, 0);
            const w = window.innerWidth && document.documentElement.clientWidth ?
                Math.min(window.innerWidth, document.documentElement.clientWidth) :
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.getElementsByTagName('body')[0].clientWidth;
            const h = window.innerHeight || document.documentElement.clientHeight;

            if (w < 992) {
                // this.videoHeight = 'auto';
                this.playerWidth = w - 10;
                this.playerTop = '-40px';
            } else {
                this.videoHeight = h - 450;
                this.playerWidth = w - 10;
                this.playerTop = 0;
            }
        },
        getVideoHeight() {
            if (this.videoHeight === 'auto') {
                return 'auto';
            } else {
                return this.videoHeight + 'px';
            }
        },
        isModerator(video) {
            return video?.read_only === false;
        },
        /*getVideoHeight82() {
            if (this.videoHeight === 'auto') {
                return 'auto'
            } else {
                return this.videoHeight + 82 + 'px';
            }
        },*/
        async changeUserAccessOfVideo(accessGranted, videoId, userId) {
            // Fix because template cannot detect change deep object
            const cloned = cloneDeep(this.usersAccess);
            // Template can detect first level change
            this.usersAccess = {};
            this.usersAccess = cloned;
            // Send request
            if (accessGranted) {
                //await addVideoAccessForUser(videoId, userId);
                this.addVideoAccessForUser({videoId: videoId, userId: userId});
            } else {
                //await deleteVideoAccessForUser(videoId, userId);
                this.deleteVideoAccessForUser({videoId: videoId, userId: userId});
            }
            //this.getVideo({id: this.id, accessTicketId: this.accessTicketId});
        },
        changePublicityCallback(videoId, publicity) {
            this.changeVideoPublicity({videoId: videoId, publicity: publicity});
        },

        getTime(value) {
            return getTime(value);
        },
        changeVideoPlayerCurrentTimeAfterScrollCallback(videoPlayerCurrentTime) {
            this.videoEl.currentTime = videoPlayerCurrentTime;
        },
        async getAllUserForAccessUsersListPopover() {
            try {
                this.allUser = await this.getAllUsers();
            } catch (e) {
                console.warn(e);
                this.allUser = [];
            }
        },
        async reInitUsersAccess() {
            // Load all user for Access Users List Popover
            // TODO: Возможно стоит убрать allUser и связанный функционал, т.к. это нужно для старой системы
            /*if (this.allUser?.length < 1) {
                await this.getAllUserForAccessUsersListPopover();
            }*/

            // Reset Users Access
            this.usersAccess = {};

            // Reinit Users Access
            const allUsers = this.allUser?.length ? this.allUser : [];

            this.usersAccess[this.id] = {};
            allUsers.forEach((ur) => {
                if (ur.is_admin) {
                    return;
                }

                const accessGranted = this.video.usersAccessGranted.find(u => u.id === ur.id);
                // If accessGranted not 'undefined' then User user have access, or don't have access
                this.usersAccess[this.id][ur.id] = typeof accessGranted !== 'undefined';
            });

        },
        getHeightTimeline(heightTimeline) {
            this.heightTimeline = heightTimeline;
        },

        // Scissors video
        toggleVideoTrimModalVisibility($eventOrBool) {
            if (typeof ($eventOrBool) === 'boolean') {
                this.videoTrimModalVisible = $eventOrBool;
            } else if ($eventOrBool) {
                if (!$eventOrBool.target.closest('.modal-dialog')) {
                    this.videoTrimModalVisible = !this.videoTrimModalVisible;
                }
                if ($eventOrBool.target.closest('.close')) {
                    this.videoTrimModalVisible = false;
                }
            } else {
                this.videoTrimModalVisible = !this.videoTrimModalVisible;
            }
            if (this?.video?.duration.totalSeconds > 60) {
                this.showModal = true;
                this.showMessage = false;
                this.$refs.videoTrimModal.style.display = this.videoTrimModalVisible ? 'block' : 'none';
            } else {
                this.showModal = false;
                this.showMessage = true;
                setTimeout(() => {
                    this.showMessage = false;
                }, 3000);
            }
            this.forcedShowModal = !this.forcedShowModal;
        },
        getHeightSkeletonProtocol(heightSkeletonPlayer) {
            this.skeletonProtocolHeightTwo = heightSkeletonPlayer;
            this.skeletonPlayer = heightSkeletonPlayer;
        },

        changeCurrentTimeOfTimeline(time) {
            this.selectedTimeOfTimeline = time;
        },
    },
});
