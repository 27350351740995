import {User} from 'oidc-client';

import {appSettings} from '@app/config';

import {createOidcAuth, LogLevel, SignInType} from './vue-oidc-client';

const appRootUrl = `${location.protocol}//${location.host}/`;

export function getIdsrvAuth() {

    const baseUrl = appSettings.authBaseUrl;

    const idsrvAuth = createOidcAuth(
        'main',
        SignInType.Window,
        appRootUrl,
        {
            authority: `${baseUrl}/`,
            client_id: 'Recorder_SPA',
            response_type: 'code',
            scope: 'Recorder',
            prompt: 'login',
            redirect_uri: appRootUrl + 'authentication/login-callback',
            post_logout_redirect_uri: appRootUrl + 'authentication/logout-callback',
            // post_logout_redirect_uri: appRootUrl,
        },
        console,
        LogLevel.Debug,
    );

    // handle events
    idsrvAuth.events.addAccessTokenExpiring(function() {
        // eslint-disable-next-line no-console
        console.log('access token expiring');
    });

    idsrvAuth.events.addAccessTokenExpired(function() {
        // eslint-disable-next-line no-console
        console.log('access token expired');
    });

    idsrvAuth.events.addSilentRenewError(function(err: Error) {
        // eslint-disable-next-line no-console
        console.error('silent renew error', err);
    });

    idsrvAuth.events.addUserLoaded(function(user: User) {
        // eslint-disable-next-line no-console
        console.log('user loaded', user);
    });

    idsrvAuth.events.addUserUnloaded(function() {
        // eslint-disable-next-line no-console
        console.log('user unloaded');
    });

    idsrvAuth.events.addUserSignedOut(function() {
        // eslint-disable-next-line no-console
        console.log('user signed out');
    });

    idsrvAuth.events.addUserSessionChanged(function() {
        // eslint-disable-next-line no-console
        console.log('user session changed');
    });

    return idsrvAuth;
}
